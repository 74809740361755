<template>
  <v-app>
    <div>
      <router-view />
    </div>
  </v-app>
</template>

<script>

export default {

  data: () => ({
    //
  }),
};
</script>

<!-- <template>
  <v-container>
  </v-container>
</template>

<script>
  export default {

    data: () => ({
     
    }),
  }
</script> -->

<!-- <v-toolbar class="tool-sty elevation-0 pt-4 mb-3 px-8" color="#fff" dense>
</v-toolbar> -->

