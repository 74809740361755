import Vue from 'vue';
import VueRouter, { NavigationFailureType, isNavigationFailure } from 'vue-router';
import LayoutSrc from '../components/Layout/LayoutSrc.vue';
import TVChartContainer from '../components/TVChartContainer.vue';

import DbLayout from '../views/Dashboard/DbLayout.vue';

import DashBoard from '../views/Dashboard/DashBoard.vue';
import PosiTions from '../views/Positions/PosiTions.vue';
import HolDings from '../views/Holdings/HolDings.vue';
import OrderScreen from '../views/Orders/OrderScreen.vue';

import FundSrc from '../views/Accounts/FundSrc.vue';
import SettingSrc from '../views/Accounts/SettingSrc.vue';
import AlertScreen from '../views/Accounts/AlertScreen.vue';

// stocks pages

import StocksSrc from '../views/Dashboard/stocks/StocksSrc.vue';
import StocksDetails from '../views/Dashboard/stocks/StocksDetails.vue';
import StocksIndices from '../views/Dashboard/stocks/StocksIndices.vue';
import StockMarket from '../views/Dashboard/stocks/StockMarket.vue';
import StockScreener from '../views/Dashboard/stocks/StockScreener.vue';
import StockAD from '../views/Dashboard/stocks/StockAD.vue';
import SingleStocks from '../views/Dashboard/stocks/SingleStocks.vue';

// mutual fund pages
import MutualSrc from '../views/Dashboard/mutualfund/MutualSrc.vue';
import MutualOrderbook from '../views/Dashboard/mutualfund/MutualOrderbook.vue';
import MutualCategorieSrc from '../views/Dashboard/mutualfund/MutualCategorieSrc.vue';
import MuturalNfoSrc from '../views/Dashboard/mutualfund/MuturalNfoSrc.vue';
import MutualSingle from '../views/Dashboard/mutualfund/MutualSingle.vue';
// import MutualCompare from '../views/Dashboard/mutualfund/MutualCompare.vue';
// import MutualAmcSrc from '../views/Dashboard/mutualfund/MutualAmcSrc.vue';

// BondSrc pages
import BondSrc from '../views/Dashboard/bonds/BondSrc.vue';
// import BondsHolding from '../views/Dashboard/bonds/BondsHolding.vue';
import BondsOrderbook from '../views/Dashboard/bonds/BondsOrderbook.vue';

// IpoSrc pages
import IpoSrc from '../views/Dashboard/ipos/IpoSrc.vue';
// import IposHolding from '../views/Dashboard/ipos/IposHolding.vue';
import IpoOrderbook from '../views/Dashboard/ipos/IpoOrderbook.vue';
import IpoPerformance from '../views/Dashboard/ipos/IpoPerformance.vue';

// fno fund pages
import FnoSrc from '../views/Dashboard/fno/FnoSrc.vue';


// CollectionSrc pages
import CollectionSrc from '../views/Dashboard/collections/CollectionSrc.vue';
import CollectionSingle from '../views/Dashboard/collections/CollectionSingle.vue';
// import BondsOrderbook from '../views/Dashboard/bonds/BondsOrderbook.vue';

import ViewsLayout from '../views/ViewsLayout.vue'

import NotFoundPage from '../views/NotFoundPage.vue'
// import TradingViewmynt from '../components/TVChartContainer.vue' 

Vue.use(VueRouter)

const originalPusher = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  originalPusher.call(this, location).catch(error => {
    if (isNavigationFailure(error, NavigationFailureType.duplicated)) {
      // throw Error(error);
    }
  })
}

const routes = [
  {
    path: '/tv',
    name: 'tv',
    component: TVChartContainer
  },
  {
    path: '/',
    redirect: '/stocks',
    name: 'layout',
    component: LayoutSrc,
    children: [
      {
        path: '/stocks',
        name: 'stocks',
        component: StocksSrc
      },
      {
        path: '/stocks/allindices',
        name: 'stocks indices',
        component: StocksIndices
      },
      {
        path: '/stocks/market',
        name: 'stocks market',
        component: StockMarket
      },
      {
        path: '/stocks/screener',
        name: 'stocks screener',
        component: StockScreener
      },
      {
        path: '/stocks/advance_decline',
        name: 'stocks advance decline',
        component: StockAD
      },
      {
        path: '/',
        redirect: '/stocks',
        name: 'dashboard',
        component: DashBoard,
        children: [
          {
            path: '/stocks/allindices',
            // redirect: '/stocks',
            name: 'dashboard layout',
            component: DbLayout,
            children: [
              {
                path: '/stocks/details',
                name: 'stocks details',
                component: StocksDetails
              },
              {
                path: '/fno',
                name: 'fno',
                component: FnoSrc
              },
            ]
          },

          {
            path: '/mutualfund',
            name: 'mutual fund home',
            component: MutualSrc
          },
          {
            path: '/mutualfund/orderbook',
            name: 'mutual fund orderbook',
            component: MutualOrderbook
          },
          {
            path: '/mutualfund/categories',
            name: 'mutual fund categories',
            component: MutualCategorieSrc
          },
          {
            path: '/mutualfund/nfo',
            name: 'mutual fund nfo',
            component: MuturalNfoSrc
          },

          {
            path: '/mutualfund/single',
            alias: '/mutualfund/*',
            name: 'mutual fund single',
            component: MutualSingle
          },
          // {
          //   path: '/mutualfund/compare',
          //   name: 'mutual fund MutualCompare',
          //   component: MutualCompare
          // },
          // {
          //   path: '/mutualfund/amc',
          //   name: 'mutual fund amc',
          //   component: MutualAmcSrc
          // },

          {
            path: '/bonds',
            name: 'bonds home',
            component: BondSrc
          },
          {
            path: '/bonds/orderbook',
            name: 'bonds orderbook',
            component: BondsOrderbook
          },
          /////////////////
          {
            path: '/ipo',
            name: 'ipos home',
            component: IpoSrc
          },
          {
            path: '/ipo/orderbook',
            name: 'ipos orderbook',
            component: IpoOrderbook
          },
          {
            path: '/ipo/performance',
            name: 'ipos performance',
            component: IpoPerformance
          },
          ///////////////////
          {
            path: '/collection',
            name: 'collection home',
            component: CollectionSrc
          },
          {
            path: '/collection/single',
            alias: '/collection/*',
            name: 'collection single',
            component: CollectionSingle
          },
        ],
      },

      {
        path: '/funds',
        name: 'funds',
        component: FundSrc
      },
      {
        path: '/settings',
        name: 'Settings',
        component: SettingSrc
      },
      {
        path: '/orders',
        name: 'orderbook',
        component: OrderScreen,
      },
      {
        path: '/positions',
        name: 'views layout',
        component: ViewsLayout,
        children: [
          {
            path: '/positions',
            name: 'positions',
            component: PosiTions,
          },
          {
            path: '/holdings',
            name: 'holdings',
            component: HolDings,
          },
          {
            path: '/notification',
            name: 'alert screen',
            component: AlertScreen,
          },
          {
            path: '/stocks/single',
            alias: '/stocks/*',
            name: 'single stocks',
            component: SingleStocks,
          },
        ]
      },

      {
        path: '*',
        // redirect: '/stocks',
        component: NotFoundPage,
      },
    ],
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  }
})
export default router

