<template>
  <div>
    <div class="mb-6">
      <v-toolbar flat dense class="tool-sty crd-trn">
        <v-btn :disabled="allbasketsdata && allbasketsdata.length == 10 ? true : false" class="elevation-0 rounded-pill font-weight-bold text-none" color="primary" height="40px" @click="(createbskdialog = true), (setmode = true)">+ Create Basket</v-btn>
        <v-spacer></v-spacer>
        <v-text-field style="max-width: 220px" v-model="opensearch" hide-details prepend-inner-icon="mdi-magnify" label="Search" class="rounded-pill" dense flat solo background-color="secbg"></v-text-field>
        <v-icon class="ml-3 cursor-p" @click="getOrderbook()" color="maintext" size="24">mdi-reload</v-icon>
      </v-toolbar>

      <v-data-table
        must-sort
        :sort-by="['date']"
        :sort-desc="[true]"
        hide-default-footer
        fixed-header
        :loading="loading"
        class="mt-3 rounded-lg overflow-y-auto"
        style="border-radius: 4px; border: 1px solid var(--outline)"
        height="480px"
        :headers="orderheader"
        :search="opensearch"
        :items="allbasketsdata"
        :items-per-page="10"
      >
        <template v-slot:[`item.name`]="{item}">
          <span class="font-weight-medium maintext--text ws-p">{{ item.name }}</span>
        </template>
        <template v-slot:[`item.list`]="{item}">
          <span class="font-weight-medium maintext--text ws-p">{{ item.list ? item.list.length : 0 }}/20</span>
        </template>
        <template v-slot:[`item.date`]="{item}">
          <span class="font-weight-medium maintext--text ws-p">{{ item.date }}</span>
        </template>
        <template v-slot:[`item.actions`]="{item}">
          <div class="d-inline-flex" @click.stop>
            <v-btn icon small class="text-align-center mr-1 mt-2" @click="setviewBSK(item)">
              <v-icon color="currentColor" size="24">mdi-eye-outline</v-icon>
            </v-btn>
            <v-btn icon small class="text-align-center mr-1 mt-2" @click="seteditBSK(item), (setmode = false)">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 28 28" fill="none">
                <path
                  d="M5 18.7782C5.00006 18.481 5.11819 18.1959 5.32841 17.9858L17.8214 5.49281C17.9775 5.33658 18.1629 5.21264 18.3669 5.12808C18.571 5.04352 18.7897 5 19.0106 5C19.2315 5 19.4502 5.04352 19.6542 5.12808C19.8583 5.21264 20.0437 5.33658 20.1998 5.49281L23.0972 8.3902C23.2534 8.54634 23.3774 8.73173 23.4619 8.93578C23.5465 9.13983 23.59 9.35854 23.59 9.57942C23.59 9.8003 23.5465 10.019 23.4619 10.2231C23.3774 10.4271 23.2534 10.6125 23.0972 10.7686L10.6042 23.2616C10.3941 23.4718 10.109 23.5899 9.81179 23.59H6.12085C5.82358 23.59 5.53849 23.4719 5.32829 23.2617C5.11809 23.0515 5 22.7664 5 22.4692V18.7782ZM6.12085 18.7782V22.4692H9.81179L22.3047 9.9762C22.3569 9.92414 22.3983 9.8623 22.4266 9.79421C22.4548 9.72613 22.4694 9.65313 22.4694 9.57942C22.4694 9.5057 22.4548 9.43271 22.4266 9.36463C22.3983 9.29654 22.3569 9.2347 22.3047 9.18264L19.4074 6.28525C19.3553 6.23306 19.2935 6.19165 19.2254 6.1634C19.1573 6.13515 19.0843 6.12061 19.0106 6.12061C18.9369 6.12061 18.8639 6.13515 18.7958 6.1634C18.7277 6.19165 18.6659 6.23306 18.6138 6.28525L6.12085 18.7782Z"
                  fill="currentColor"
                />
                <path d="M16.3721 7.73451L20.8555 12.2179L21.649 11.4243L17.1657 6.94095L16.3721 7.73451ZM6.28448 17.8221L10.7679 22.3055L11.5614 21.512L7.07804 17.0286L6.28448 17.8221Z" fill="currentColor" />
                <path d="M18.6138 9.18265L8.52618 19.2703L9.31974 20.0638L19.4074 9.97621L18.6138 9.18265Z" fill="currentColor" />
              </svg>
            </v-btn>
            <v-btn icon small class="text-align-center mt-2" @click="(singledata = item), (canceldialog = true)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="24" height="24">
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M11.5 6a.5.5 0 0 0-.5.5V8h6V6.5a.5.5 0 0 0-.5-.5h-5zM18 8V6.5c0-.83-.67-1.5-1.5-1.5h-5c-.83 0-1.5.67-1.5 1.5V8H5.5a.5.5 0 0 0 0 1H7v12.5A2.5 2.5 0 0 0 9.5 24h9a2.5 2.5 0 0 0 2.5-2.5V9h1.5a.5.5 0 0 0 0-1H18zm2 1H8v12.5c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5V9zm-8.5 3c.28 0 .5.22.5.5v7a.5.5 0 0 1-1 0v-7c0-.28.22-.5.5-.5zm5.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
                ></path>
              </svg>
            </v-btn>
          </div>
        </template>
        <template v-slot:no-data>
          <v-col cols="12" class="text-center pa-16">
            <div class="mx-auto">
              <img class="align-self-stretch mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" alt="no data" />
              <h5 class="txt-999 font-weight-regular">There is no data here yet!</h5>
            </div>
          </v-col>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="basketdialog" hide-overlay width="740">
      <v-card class="pb-6 px-6 overflow-hidden rounded-lg" color="cardbg">
        <v-list-item-title class="font-weight-bold title maintext--text mt-4 mb-1">Basket order <v-icon @click="basketdialog = false" class="float-right" color="maintext" :disabled="orderloader">mdi-close</v-icon></v-list-item-title>
        <v-divider class="mb-2"></v-divider>
        <v-row class="pt-2 pb-4">
          <v-col cols="4" class="d-inline-flex">
            <v-text-field readonly hide-details height="40px" dense background-color="secbg" flat class="rounded-pill mb-0" type="text" solo v-model="singledata.name"> </v-text-field>

            <v-btn icon small class="text-align-center ml-1 mt-1" @click="canceldialog = true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="24" height="24">
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M11.5 6a.5.5 0 0 0-.5.5V8h6V6.5a.5.5 0 0 0-.5-.5h-5zM18 8V6.5c0-.83-.67-1.5-1.5-1.5h-5c-.83 0-1.5.67-1.5 1.5V8H5.5a.5.5 0 0 0 0 1H7v12.5A2.5 2.5 0 0 0 9.5 24h9a2.5 2.5 0 0 0 2.5-2.5V9h1.5a.5.5 0 0 0 0-1H18zm2 1H8v12.5c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5V9zm-8.5 3c.28 0 .5.22.5.5v7a.5.5 0 0 1-1 0v-7c0-.28.22-.5.5-.5zm5.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
                ></path>
              </svg>
            </v-btn>
          </v-col>
          <v-col cols="5" class="ml-auto">
            <v-autocomplete
              :disabled="singledata.list && singledata.list.length == 20 ? true : false || (model && model.set)"
              @change="setListinbsk()"
              :loading="searchloading"
              item-text="tsym"
              return-object
              class="rounded-pill"
              flat
              solo
              background-color="secbg"
              dense
              v-model="model"
              :search-input.sync="search"
              hide-details
              label="Search script"
              :items="items"
              prepend-inner-icon="mdi-magnify"
              append-icon=""
              no-filter
              oninput="this.value = this.value.toUpperCase()"
              clearable
            >
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-card v-if="model && model.token" :loading="orderloader" :color="!buyrsell ? 'secgreen' : 'secred'" class="elevation-0 pt-3 pb-4">
          <v-toolbar class="tool-sty elevation-0 pl-4 pr-2 crd-trn" dense>
            <v-list-item class="px-0">
              <v-list-item-content class="pt-1">
                <v-list-item-title class="font-weight-bold fs-16 maintext--text mb-2"
                  >{{ model ? model.tsym : "" }} <span class="ml-1 subtext--text fs-10">{{ model ? model.exch : "" }}</span></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-spacer></v-spacer>
            <v-card class="rounded-md elevation-0 py-1 px-2 font-weight-bold fs-10 white--text mr-4 mt-n5" color="maingreen"> B </v-card>
            <v-switch v-model="buyrsell" inset></v-switch>
            <v-card class="rounded-md elevation-0 py-1 px-2 font-weight-bold fs-10 white--text ml-1 mt-n5" color="mainred"> S </v-card>

            <v-icon @click="model = []" color="maintext" class="mb-auto ml-4">mdi-close</v-icon>
          </v-toolbar>

          <v-row no-glutters class="px-4 pb-4">
            <v-col cols="3" class="pb-0">
              <p class="font-weight-regular fs-14 subtext--text mb-2">Investment type</p>
              <v-select height="40px" dense background-color="cardbg" flat block class="rounded-pill" solo item-text="txt" item-value="val" append-icon="mdi-chevron-down" :items="investitems[model.exch == 'NSE' || model.exch == 'BSE' ? 0 : 1]" hide-details v-model="investype"> </v-select>
            </v-col>
            <v-col cols="3" class="pb-0">
              <p class="font-weight-regular fs-14 subtext--text mb-2">Order type</p>
              <v-select height="40px" dense background-color="cardbg" flat block class="rounded-pill" solo item-text="txt" append-icon="mdi-chevron-down" :items="prcitems" hide-details item-value="val" v-model="prctype"> </v-select>
            </v-col>
            <v-col cols="3" class="pb-0">
              <p class="font-weight-regular fs-14 subtext--text mb-2">Quantity</p>
              <v-text-field height="40px" dense background-color="cardbg" flat block hide-details class="rounded-pill" solo type="number" hide-spin-buttons :min="Number(model.ls)" :step="Number(model.ls)" v-model="ordqty">
                <template #append>
                  <v-btn @click="ordqty = Number(ordqty) + Number(model.ls)" icon class="elevation-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <circle cx="12" cy="12" r="12" fill="white" />
                      <path d="M12 8V16" stroke="#666666" stroke-width="2" stroke-linecap="round" />
                      <path d="M16 12L8 12" stroke="#666666" stroke-width="2" stroke-linecap="round" />
                    </svg>
                  </v-btn>
                </template>

                <template #prepend-inner>
                  <v-btn @click="ordqty == Number(model.ls) ? Number(ordqty) : (ordqty = Number(ordqty) - Number(model.ls))" icon class="elevation-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <circle cx="12" cy="12" r="12" fill="white" />
                      <path d="M16 12L8 12" stroke="#666666" stroke-width="2" stroke-linecap="round" />
                    </svg>
                  </v-btn>
                </template>
              </v-text-field>
              <p v-if="model[0]" class="lh-16 fs-10 subtext--text mb-0">Freeze qty: {{ model[0].frzqty }}</p>
            </v-col>
            <v-col v-if="prctype == 'LMT' || prctype == 'SL-LMT'" cols="3" class="pb-0">
              <p class="font-weight-regular fs-14 subtext--text mb-2">Price</p>
              <v-text-field height="40px" dense background-color="cardbg" flat block class="rounded-pill" solo type="number" hide-spin-buttons hide-details :min="model.ti" v-model="price" v-on:keyup.enter="setBSKlist()">
                <template #prepend-inner>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="transparent" />
                    <path
                      d="M15.4285 9.25426L15.0805 10.4574H8.58762L8.93066 9.25426H15.4285ZM12.3014 17L8.71689 12.6399L8.70197 11.7102H10.4818C10.9326 11.7102 11.3137 11.6423 11.6253 11.5064C11.9401 11.3705 12.1788 11.1716 12.3412 10.9098C12.5069 10.648 12.5898 10.3232 12.5898 9.93537C12.5898 9.36198 12.4191 8.90956 12.0777 8.57812C11.7363 8.24337 11.2043 8.07599 10.4818 8.07599H8.58762L8.94061 6.81818H10.4818C11.3005 6.81818 11.9733 6.95076 12.5003 7.21591C13.0306 7.47775 13.425 7.84067 13.6835 8.30469C13.942 8.7687 14.0713 9.29901 14.0713 9.8956C14.0713 10.4325 13.9603 10.9214 13.7382 11.3622C13.5194 11.7997 13.1698 12.156 12.6892 12.4311C12.2086 12.7029 11.5756 12.8537 10.79 12.8835L10.7453 12.8935L14.0365 16.9155V17H12.3014ZM15.4285 6.81818L15.0805 8.03125L9.91504 7.99645L10.2631 6.81818H15.4285Z"
                      fill="#666666"
                    />
                  </svg>
                </template>
              </v-text-field>
              <p v-if="model[0]" class="lh-16 fs-10 subtext--text mb-0">Circuit level: {{ model[0].lc }} - {{ model[0].uc }}</p>
            </v-col>
            <v-col v-if="prctype == 'SL-MKT' || prctype == 'SL-LMT'" cols="3" class="pb-0">
              <p class="font-weight-regular fs-14 subtext--text mb-2">Trigger</p>
              <v-text-field height="40px" dense background-color="cardbg" flat block class="rounded-pill" solo type="number" hide-spin-buttons hide-details :min="model.ti" :step="model.ti" v-model="trgprice" v-on:keyup.enter="setBSKlist()">
                <template #prepend-inner>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="white" />
                    <path
                      d="M15.4285 9.25426L15.0805 10.4574H8.58762L8.93066 9.25426H15.4285ZM12.3014 17L8.71689 12.6399L8.70197 11.7102H10.4818C10.9326 11.7102 11.3137 11.6423 11.6253 11.5064C11.9401 11.3705 12.1788 11.1716 12.3412 10.9098C12.5069 10.648 12.5898 10.3232 12.5898 9.93537C12.5898 9.36198 12.4191 8.90956 12.0777 8.57812C11.7363 8.24337 11.2043 8.07599 10.4818 8.07599H8.58762L8.94061 6.81818H10.4818C11.3005 6.81818 11.9733 6.95076 12.5003 7.21591C13.0306 7.47775 13.425 7.84067 13.6835 8.30469C13.942 8.7687 14.0713 9.29901 14.0713 9.8956C14.0713 10.4325 13.9603 10.9214 13.7382 11.3622C13.5194 11.7997 13.1698 12.156 12.6892 12.4311C12.2086 12.7029 11.5756 12.8537 10.79 12.8835L10.7453 12.8935L14.0365 16.9155V17H12.3014ZM15.4285 6.81818L15.0805 8.03125L9.91504 7.99645L10.2631 6.81818H15.4285Z"
                      fill="#666666"
                    />
                  </svg>
                </template>
              </v-text-field>
            </v-col>
            <v-col v-if="prctype == 'MKT' || prctype == 'SL-MKT'" cols="3" class="pb-0">
              <p class="font-weight-regular fs-14 subtext--text mb-2">Market Production %</p>
              <v-text-field height="40px" dense background-color="cardbg" flat hide-details class="rounded-pill" solo type="number" hide-spin-buttons min="2" max="100" step="1" v-model="mktprdordqty" v-on:keyup.enter="setBSKlist()"> </v-text-field>
            </v-col>
            <v-col cols="3" class="pb-0">
              <p class="font-weight-regular fs-14 subtext--text mb-2">AMO</p>
              <v-checkbox color="maintext" v-model="addmktord" hide-details>
                <template #label>
                  <p class="font-weight-regular fs-14 subtext--text mb-0">Add Market order</p>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="3" class="pb-0 ml-auto mt-auto">
              <v-btn @click="setBSKlist()" :loading="orderloader" :color="buyrsell ? 'mainred' : 'maingreen'" class="text-none rounded-pill elevation-0 white--text" block height="40px"> {{ model.set ? "Edit" : "Add" }} </v-btn>
            </v-col>
          </v-row>
        </v-card>

        <v-data-table v-else must-sort :sort-by="['idx']" :sort-desc="[true]" hide-default-footer fixed-header :loading="loading" class="mt-0 rounded-lg overflow-y-auto" style="border: 1px solid var(--outline)" height="288px" :headers="singlebskheader" :items="orderbookdata" :items-per-page="20">
          <template v-slot:[`item.tsym`]="{item}">
            <p class="font-weight-medium maintext--text mb-0 ws-p">
              {{ item.tsym ? item.tsym : "" }}
              <span class="ml-1 txt-999 fs-10">{{ item.exch ? item.exch : "" }}</span>
            </p>
          </template>
          <template v-slot:[`item.qty`]="{item}">
            <v-chip small class="table-hov-prd" text-color="subtext" style="border-radius: 5px; padding: 10px 8px !important">
              <span class="font-weight-medium maintext--text fs-12">{{ item.qty }}</span>
            </v-chip>
          </template>
          <template v-slot:[`item.investype`]="{item}">
            <span class="font-weight-medium maintext--text ws-p">{{ investitems[item.investype] }}</span>
          </template>
          <template v-slot:[`item.buyrsell`]="{item}">
            <v-chip small :color="!item.buyrsell ? 'secgreen' : 'secred'" :text-color="!item.buyrsell ? 'maingreen' : 'mainred'" :style="`border: 1px solid ${!item.buyrsell ? '#C1E7BA' : '#FFCDCD'}; border-radius: 5px; padding: 10px 8px !important;`">
              <span class="font-weight-medium fs-12">{{ item.buyrsell ? "SELL" : "BUY" }}</span>
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{item}">
            <div class="d-inline-flex" @click.stop>
              <v-btn icon small class="text-align-center mr-1 mt-2" @click="setListinbsk(true, item)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 28 28" fill="none">
                  <path
                    d="M5 18.7782C5.00006 18.481 5.11819 18.1959 5.32841 17.9858L17.8214 5.49281C17.9775 5.33658 18.1629 5.21264 18.3669 5.12808C18.571 5.04352 18.7897 5 19.0106 5C19.2315 5 19.4502 5.04352 19.6542 5.12808C19.8583 5.21264 20.0437 5.33658 20.1998 5.49281L23.0972 8.3902C23.2534 8.54634 23.3774 8.73173 23.4619 8.93578C23.5465 9.13983 23.59 9.35854 23.59 9.57942C23.59 9.8003 23.5465 10.019 23.4619 10.2231C23.3774 10.4271 23.2534 10.6125 23.0972 10.7686L10.6042 23.2616C10.3941 23.4718 10.109 23.5899 9.81179 23.59H6.12085C5.82358 23.59 5.53849 23.4719 5.32829 23.2617C5.11809 23.0515 5 22.7664 5 22.4692V18.7782ZM6.12085 18.7782V22.4692H9.81179L22.3047 9.9762C22.3569 9.92414 22.3983 9.8623 22.4266 9.79421C22.4548 9.72613 22.4694 9.65313 22.4694 9.57942C22.4694 9.5057 22.4548 9.43271 22.4266 9.36463C22.3983 9.29654 22.3569 9.2347 22.3047 9.18264L19.4074 6.28525C19.3553 6.23306 19.2935 6.19165 19.2254 6.1634C19.1573 6.13515 19.0843 6.12061 19.0106 6.12061C18.9369 6.12061 18.8639 6.13515 18.7958 6.1634C18.7277 6.19165 18.6659 6.23306 18.6138 6.28525L6.12085 18.7782Z"
                    fill="currentColor"
                  />
                  <path d="M16.3721 7.73451L20.8555 12.2179L21.649 11.4243L17.1657 6.94095L16.3721 7.73451ZM6.28448 17.8221L10.7679 22.3055L11.5614 21.512L7.07804 17.0286L6.28448 17.8221Z" fill="currentColor" />
                  <path d="M18.6138 9.18265L8.52618 19.2703L9.31974 20.0638L19.4074 9.97621L18.6138 9.18265Z" fill="currentColor" />
                </svg>
              </v-btn>
              <v-btn icon small class="text-align-center mt-2" @click="setListcancel(item)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="24" height="24">
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M11.5 6a.5.5 0 0 0-.5.5V8h6V6.5a.5.5 0 0 0-.5-.5h-5zM18 8V6.5c0-.83-.67-1.5-1.5-1.5h-5c-.83 0-1.5.67-1.5 1.5V8H5.5a.5.5 0 0 0 0 1H7v12.5A2.5 2.5 0 0 0 9.5 24h9a2.5 2.5 0 0 0 2.5-2.5V9h1.5a.5.5 0 0 0 0-1H18zm2 1H8v12.5c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5V9zm-8.5 3c.28 0 .5.22.5.5v7a.5.5 0 0 1-1 0v-7c0-.28.22-.5.5-.5zm5.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
                  ></path>
                </svg>
              </v-btn>
            </div>
          </template>
          <template v-slot:no-data>
            <v-col cols="12" class="text-center pa-16">
              <div class="mx-auto">
                <img class="align-self-stretch mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" alt="no data" />
                <h5 class="txt-999 font-weight-regular">There is no data here yet!</h5>
              </div>
            </v-col>
          </template>
          <!-- <template v-slot:footer>
            <v-divider></v-divider>
          
          </template> -->
        </v-data-table>
        <v-row class="pt-3">
          <v-col cols="8" class="pa-0 pl-1">
            <v-list dense class="crd-trn">
              <v-list-item class="px-0 pl-2">
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2 font-weight-regular ml-1">
                    Basket Margin: ₹<span class="font-weight-bold">{{ totalmargin }}</span>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2 font-weight-regular">
                    Post Trade Margin: ₹<span class="font-weight-bold">{{ postTrademargin }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="1" class="text-center">
            <v-btn @click="getOBMargin()" :disabled="orderloader || (model && model.token)" text x-small fab icon>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path
                  fill="currentColor"
                  d="M16.63 7.05a6.78 6.78 0 0 0-11.2 3.29.49.49 0 0 1-.47.37H2.65a.48.48 0 0 1-.48-.57 10 10 0 0 1 16.74-5.37l1.44-1.44A.97.97 0 0 1 22 4v5.4c0 .54-.43.98-.97.98h-5.4a.97.97 0 0 1-.69-1.65l1.69-1.69ZM2.97 13.61h5.4a.97.97 0 0 1 .69 1.66l-1.69 1.68a6.78 6.78 0 0 0 11.2-3.29.49.49 0 0 1 .47-.37h2.31c.3 0 .53.27.48.57a10 10 0 0 1-16.74 5.37l-1.44 1.44A.97.97 0 0 1 2 20v-5.4c0-.54.43-.98.97-.98Z"
                ></path>
              </svg>
            </v-btn>
          </v-col>
          <v-col cols="3" class="text-right">
            <v-btn :disabled="(model && model.token) || orderbookdata.length == 0" :loading="orderloader" color="btnclr" class="elevation-0 text-capitalize rounded-pill btntext--text" @click="setBfoPlaceorder(0)">Place Order </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="createbskdialog" width="420">
      <v-card class="pb-4 pt-2 px-6 overflow-hidden elevation-0" color="cardbg">
        <v-list-item-title class="font-weight-bold title maintext--text mt-2 mb-4"
          >{{ !setmode ? "Edit" : "Create" }}
          basket
          <v-icon @click="createbskdialog = false" class="float-right" color="maintext">mdi-close</v-icon></v-list-item-title
        >
        <p class="font-weight-regular fs-14 subtext--text mb-2">Enter the basket name</p>
        <v-text-field
          hide-details
          height="40px"
          dense
          background-color="secbg"
          flat
          class="rounded-pill mb-4"
          placeholder="my first millions"
          v-on:keypress="isLetter($event)"
          v-on:keyup.enter="!setmode ? seteditBSK() : setCreatebasket(), (createbskdialog = false)"
          type="text"
          solo
          v-model="bskname"
        >
        </v-text-field>
        <v-btn @click="!setmode ? seteditBSK() : setCreatebasket(), (createbskdialog = false)" :disabled="!bskname" color="btnclr" class="text-none rounded-pill elevation-0 btntext--text px-6 float-right" height="40px">
          {{ !setmode ? "Edit" : "Create" }}
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="canceldialog" max-width="400">
      <v-card class="rounded-xl elevation-0 text-center pt-8 pb-6 overflow-hidden" color="cardbg">
        <img src="@/assets/orderbook/cancel-icon.svg" alt="cancel icon" />
        <p class="font-weight-medium mt-3 fs-22 lh-24 mb-8">
          Are you sure you want to <br />
          delete this <b>{{ singledata ? singledata.name : "" }}</b> basket
        </p>
        <v-row class="px-6" no-gluttters>
          <v-col cols="6">
            <v-btn @click="(canceldialog = false), (singledata = []), (basketdialog = false)" color="secbg" class="rounded-pill text-none subtext--text font-weight-bold elevation-0" block height="40px">No</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn @click="(canceldialog = false), setcancelBSK(singledata), (basketdialog = false)" color="btnclr" class="rounded-pill text-none btntext--text font-weight-bold elevation-0" block height="40px">Yes</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {getBSKMargin, getPlaceOrder, getSecuritydata, getKambalaSearch} from "../../components/mixins/getAPIdata.js";

import eventBus from "../../eventBus.js";

export default {
  data: () => ({
    uid: null,
    mtoken: null,
    stoken: null,

    setmode: true,
    canceldialog: false,
    basketdialog: false,
    createbskdialog: false,
    singledata: [],
    loading: false,

    orderloader: false,

    allbasketsdata: [],

    investitems: {
      0: [
        {txt: "Intraday", val: "I"},
        {txt: "Delivery", val: "C"},
      ],
      1: [
        {txt: "Intraday", val: "I"},
        {txt: "Carry Forward", val: "M"},
      ],
      I: "Intraday",
      C: "Delivery",
      M: "Carry Forward",
      H: "Cover order",
      B: "Bracket order",
    },

    prcitems: [
      {txt: "Limit", val: "LMT"},
      {txt: "Market", val: "MKT"},
      {txt: "SL Limit", val: "SL-LMT"},
      {txt: "SL Mkt", val: "SL-MKT"},
    ],

    buyrsell: false,
    ordqty: 0,
    investype: "I",
    prctype: "LMT",
    price: 0,
    trgprice: 0,
    mktprdordqty: 5,
    addmktord: false,

    totalmargin: "0.00",
    postTrademargin: "0.00",

    opensearch: null,
    orderbookdata: [],

    bskname: null,

    searchloading: false,
    items: [],
    model: null,
    search: null,
    nodata: null,
  }),
  watch: {
    async search(val) {
      if (val && val.length > 2) {
        this.searchloading = true;
        let res = await getKambalaSearch(`jData={"uid":"${this.uid}","stext":"${val}"}&jKey=${this.mtoken}`);
        this.nodata = null;
        if (res.stat == "Ok" || res.values) {
          var data = res.values;
          for (let c = 0; c < this.orderbookdata.length; c++) {
            let Index = data.findIndex((script) => script.token == this.orderbookdata[c].token);
            if (Index >= 0) {
              data.splice(Index, 1);
            }
          }
          this.items = data;
        } else {
          this.items = [];
          this.nodata = "noooo";
        }
        this.searchloading = false;
      }
    },
  },
  computed: {
    orderheader() {
      return [
        {text: "Basket Name", sortable: false, value: "name"},
        {text: "No.of item", value: "list"},
        {text: "Created on", value: "date"},
        {text: "", width: "10%", value: "actions", sortable: false},
      ];
    },
    singlebskheader() {
      return [
        // { text: "Exch", align: "start", sortable: false, value: "exch" },
        {text: "Instrument", value: "tsym"},
        {text: "Buy/Sell", value: "buyrsell"},
        {text: "Product", value: "investype"},
        {text: "Price type", value: "prctype"},
        {text: "Qty", value: "qty", sortable: false},
        {text: "Price", value: "price", sortable: false},
        {text: "", width: "10%", value: "actions", sortable: false},
      ];
    },
  },
  mounted() {
    let res = sessionStorage.getItem("c3RhdHVz");
    if (res == "dmFsaWR1c2Vy") {
      this.mtoken = sessionStorage.getItem("msession");
      this.uid = sessionStorage.getItem("userid");
      this.getOrderbook();
    } else {
      this.$router.push("/");
    }

    eventBus.$on("orderbook-update", (book) => {
      if (book == "bsk") {
        this.getOrderbook();
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("orderbook-update");
  },
  methods: {
    async setListinbsk(set, item) {
      let secudata = item ? `${item.exch}|${item.token}` : `${this.model.exch}|${this.model.token}`;
      if (set && item) {
        this.buyrsell = item.buyrsell;
        this.ordqty = Number(item.ls);
        this.investype = item.investype;
        this.prctype = item.prctype;
        this.price = item.price;
        this.trgprice = item.trgprice;
        item["set"] = true;
        this.model = item;
        this.mktprdordqty = item.mktprdordqty;
        this.addmktord = item.addmktord;
      } else {
        this.buyrsell = false;
        this.ordqty = Number(this.model.ls);
        this.investype = "I";
        this.prctype = "LMT";
        this.price = 0;
        this.mktprdordqty = 5;
        this.addmktord = false;
      }
      secudata = await getSecuritydata(secudata);
      this.model["0"] = secudata;
    },
    setCreatebasket() {
      var basket = {
        name: this.bskname,
        date: new Date().toDateString(),
        idx: Date.parse(new Date()),
        list: [],
      };
      var ind;
      let bskis = localStorage.getItem(`${this.uid}_basketorder`);
      bskis = bskis ? JSON.parse(bskis) : [];
      if (bskis && bskis.length > 0) {
        ind = this.allbasketsdata.findIndex((o) => o.name == this.bskname);
        ind == -1 ? this.allbasketsdata.push(basket) : "";
      } else {
        this.allbasketsdata = [basket];
      }

      if (ind >= 0) {
        eventBus.$emit("snack-event", 2, `${this.bskname}, script is already exists.`);
      } else {
        this.setBaskstorage(this.allbasketsdata);
        eventBus.$emit("snack-event", 1, "Basket have been Created");
      }
      this.bskname = null;
    },
    setBSKlist() {
      let bskis = false;
      if (!(this.ordqty > 0)) {
        eventBus.$emit("snack-event", 2, "The order Quantity must be greater than zero.");
      } else if (((this.prctype == "LMT" || this.prctype == "SL-LMT") && !(Number(this.price) > Number(this.model[0].lc))) || !(Number(this.price) < Number(this.model[0].uc))) {
        eventBus.$emit("snack-event", 2, "The order price must be between the circuit level.");
      } else if (this.prctype == "SL-MKT" || (this.prctype == "SL-LMT" && !(Number(this.trgprice) > 0))) {
        eventBus.$emit("snack-event", 2, "The trigger price cannot be zero.");
      } else if (!(this.ordqty <= Number(this.model[0].frzqty))) {
        eventBus.$emit("snack-event", 2, "You have reached max freeze Quantity");
      } else {
        bskis = true;
      }
      if (bskis) {
        var list = [];
        list = {
          buyrsell: this.buyrsell,
          qty: this.ordqty,
          idx: Date.parse(new Date()),
          investype: this.investype,
          prctype: this.prctype,
          price: this.price,
          trgprice: this.trgprice,
          mktprdordqty: this.mktprdordqty,
          addmktord: this.addmktord,
        };
        let merge = {...this.model, ...list};
        var ind = this.allbasketsdata.findIndex((o) => o.idx == this.singledata.idx);
        if (ind >= 0) {
          if (this.model && this.model.set) {
            var did = this.orderbookdata.findIndex((o) => o.token == this.model.token);
            this.orderbookdata[did] = merge;
            this.singledata.list[did] = merge;
          } else {
            this.orderbookdata.push(merge);
            this.singledata.list = this.orderbookdata;
          }
          this.allbasketsdata[ind].name = this.singledata.name;
          this.allbasketsdata[ind].list = this.orderbookdata;
          this.setBaskstorage(this.allbasketsdata);
        }
        this.model = [];
        this.getOBMargin();
      }
    },
    getOrderbook() {
      let bskis = localStorage.getItem(`${this.uid}_basketorder`);
      bskis = bskis ? JSON.parse(bskis) : [];
      if (bskis && bskis.length > 0) {
        this.allbasketsdata = bskis;
      } else {
        this.allbasketsdata = [];
      }
    },
    seteditBSK(item) {
      if (item) {
        this.bskname = item.name;
        this.singledata = item;
        this.createbskdialog = true;
      } else {
        var ind = this.allbasketsdata.findIndex((o) => o.idx == this.singledata.idx);
        this.allbasketsdata[ind].name = this.bskname;
        this.singledata.name = this.bskname;
        this.bskname = null;
        this.setBaskstorage(this.allbasketsdata);
      }
    },
    setviewBSK(item) {
      this.model = [];
      this.singledata = item;
      this.orderbookdata = item.list;
      this.basketdialog = true;
      this.getOBMargin();
    },
    setcancelBSK(item) {
      let Index = this.allbasketsdata.findIndex((script) => script.idx == item.idx);
      this.allbasketsdata.splice(Index, 1);
      this.setBaskstorage(this.allbasketsdata);

      eventBus.$emit("snack-event", 2, "Basket Order have been Cancelled");
      this.singledata = [];
    },
    setListcancel(item) {
      var did = this.orderbookdata.findIndex((o) => o.token == item.token);
      if (did >= 0) {
        this.orderbookdata.splice(did, 1);
        this.singledata.list = this.orderbookdata;
      }

      let Index = this.allbasketsdata.findIndex((o) => o.idx == this.singledata.idx);
      if (Index >= 0) {
        this.allbasketsdata[Index].list = this.orderbookdata;
      }
      this.setBaskstorage(this.allbasketsdata);

      this.getOBMargin();
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char) || char == " ") return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    setSSDtab(type, token, exch, tsym) {
      if (type == "alert") {
        eventBus.$emit("menudialog", "alert", token, exch, tsym);
      } else if (type == "cGTT") {
        this.positiondrawer = false;
        eventBus.$emit("menudialog", "order-GTT", token, exch, tsym, "b");
      } else {
        let path = [type, token, exch, tsym];
        this.$router.push({name: "stocks details", params: {val: path}});
      }
    },
    setBfoPlaceorder(i) {
      this.orderloader = true;
      if (i <= this.orderbookdata.length - 1) {
        this.setPlaceorder(this.orderbookdata[i], i);
      } else {
        eventBus.$emit("snack-event", 1, `Basket order placed successfully`);
        eventBus.$emit("orderbook-update", "orders");
        this.orderloader = false;
      }
    },
    async setPlaceorder(data, i) {
      let item = {
        uid: this.uid,
        actid: this.uid,
        // "amo": this.addmktord ? 'Yes' : '',
        // "blprc": this.ordertype == 1 || this.ordertype == 2 ? this.ordslprice.toString() : '',
        // "bpprc": this.ordertype == 2 ? this.ordtagprice.toString() : '',
        // "dscqty": this.addvalqty ? this.orddisqty.toString() : '',
        exch: data.exch,
        tsym: data.tsym,
        ret: "DAY",
        qty: data.qty.toString(),
        prc: (data.exch == "MCX" || data.exch == "BSE") && (data.prctype == "MKT" || data.prctype == "SL-MKT") ? "" : data.price.toString(),
        prd: data.investype,
        trantype: data.buyrsell ? "S" : "B",
        prctyp: data.prctype == "SL-LMT" ? "SL LMT" : data.prctype == "SL-MKT" ? "SL MKT" : data.prctype == "LMT" ? "LMT" : "MKT",
        // "trgprc": (data.prctype == "SL-LMT" || data.prctype == "SL-MKT") ? this.ordtrgprice.toString() : '',
        // "mktProt": (data.prctype == 'MKT' || data.prctype == 'SL-MKT') ? this.mktprdordqty.toString() : '',
        trailprc: "",
        channel: "",
        userAgent: "",
        appInstaId: "",
      };
      data.addmktord ? (item["amo"] = "Yes") : "";
      // (this.ordertype == 1 || this.ordertype == 2) ? item['blprc'] = this.ordslprice.toString() : '';
      // this.ordertype == 2 ? item['bpprc'] = this.ordtagprice.toString() : '';
      // this.addvalqty ? item['dscqty'] = this.orddisqty.toString() : '';
      data.prctype == "SL-LMT" || data.prctype == "SL-MKT" ? (item["trgprc"] = data.trgprice.toString()) : "";
      data.prctype == "MKT" || data.prctype == "SL-MKT" ? (item["mktProt"] = data.mktprdordqty.toString()) : "";
      let odata = await getPlaceOrder(item);
      if (odata.stat != "Ok") {
        eventBus.$emit("snack-event", 2, odata.emsg ? odata.emsg : odata);
        this.orderloader = false;
      }
      setTimeout(() => {
        this.setBfoPlaceorder(i + 1);
      }, 100);
    },
    async getOBMargin() {
      if (this.orderbookdata && this.orderbookdata.length > 0) {
        let item;
        for (let i = 0; i < this.orderbookdata.length; i++) {
          if (i == 0) {
            item = {
              uid: this.uid,
              actid: this.uid,
              discqty: "0",
              exch: this.orderbookdata[i].exch,
              pCode: this.orderbookdata[i].investype,
              prctyp: this.orderbookdata[i].prctype,
              prd: this.orderbookdata[i].investype,
              prc: this.orderbookdata[i].prctype,
              qty: String(this.orderbookdata[i].qty),
              ret: "DAY",
              symbol_id: this.orderbookdata[i].token,
              tsym: this.orderbookdata[i].tsym,
              trantype: this.orderbookdata[i].buyrsell ? "S" : "B",
              trgprc: this.orderbookdata[i].prctype == "SL-LMT" || this.orderbookdata[i].prctype == "SL-MKT" ? this.orderbookdata[i].trgprice : "0",
              basketlists: [],
            };
          } else if (i > 0) {
            item.basketlists.push({
              uid: this.uid,
              actid: this.uid,
              discqty: "0",
              exch: this.orderbookdata[i].exch,
              pCode: this.orderbookdata[i].investype,
              prctyp: this.orderbookdata[i].prctype,
              prd: this.orderbookdata[i].investype,
              prc: this.orderbookdata[i].prctype,
              qty: String(this.orderbookdata[i].qty),
              ret: "DAY",
              symbol_id: this.orderbookdata[i].token,
              tsym: this.orderbookdata[i].tsym,
              trantype: this.orderbookdata[i].buyrsell ? "S" : "B",
              trgprc: this.orderbookdata[i].prctype == "SL-LMT" || this.orderbookdata[i].prctype == "SL-MKT" ? this.orderbookdata[i].trgprice : "0",
            });
          }
        }

        let marginData = await getBSKMargin(item);
        this.totalmargin = marginData.marginusedtrade == undefined ? 0 : marginData.marginused;
        this.postTrademargin = marginData.marginusedtrade == undefined ? 0 : marginData.marginusedtrade;
      }
    },
    setBaskstorage(data) {
      localStorage.setItem(`${this.uid}_basketorder`, JSON.stringify(data));
    },
  },
};
</script>
