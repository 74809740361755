import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify(
  {
    theme: {
      themes: {
        light: {
          
          primary: '#0037B7',
          secondary: '#0037B7',
          
          mainbg: '#fff',
          maintext: '#000',
          secbg: '#F1F3F8',
          subtext: '#666666',
          outline: '#EBEEF0',
          subline: '#454344',
          mainred:'#F23645',
          maingreen: '#43A833',

          secred: '#FFCDCD',
          secgreen: '#ECF8F1',

          btnclr: '#000',
          btntext: '#fff',

          cardbg: '#ffffff',
          primhover: '#CFD9F2',
        },
        dark: {
          primary: '#2E65F6',
          secondary: "#2E65F6",

          mainbg: '#000',
          maintext: '#fff',
          secbg: '#2a2a2a',
          subtext: '#999999',
          outline: '#454344',
          subline: '#EBEEF0',
          mainred: '#FF1717',
          maingreen: '#1BBC00',

          secred: '#4d0000',
          secgreen: '#163d10',

          btnclr: '#B0BEC5',
          btntext: '#000',

          cardbg: '#121212',
          primhover: '#2E65F61a',
        },
      },
    },
  }
  //   {
  //     theme: {
  //         themes: {
  //           light: {
  //             primary: '#0037B7',
  //             secondary: '#0037B7',    //blue

  //           },
  //           dark: {
  //             primary: '#2E65F6',
  //             secondary: "#2E65F6",   //blue
  //           },
  //         },
  //       },
  // }
);
