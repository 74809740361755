<template>
  <div>
    <v-row no-glutters>
      <v-col cols="12">
        <v-card class="elevation-0 mb-4 mb-md-14 pa-4 pa-md-6 rounded-lg" color="#148564">
          <v-row no-glutters>
            <v-col md="4" cols="12">
              <p class="fs-26 d-md-none font-weight-bold lh-24 white--text">Invest in Bonds</p>
              <p class="fs-36 d-none d-md-flex font-weight-bold lh-32 white--text">Invest in Bonds</p>
              <p class="title white--text mb-0 lh-24 font-weight-regular">Handpicked bonds from our experts that meet your investment needs.</p>
              <!-- <v-btn
                                class="elevation-0 white--text font-weight-bold px-6 rounded-pill text-none fs-14 mb-2"
                                large color="#000000">Invest in Bonds</v-btn> -->
            </v-col>
            <v-col cols="7" class="d-none ml-auto mr-4 d-md-flex pos-rlt">
              <v-row style="width: 100%" no-glutters class="pos-abs">
                <v-col cols="4" class="pr-0">
                  <v-card width="100%" class="pt-8 pb-3 stk-land-crds rounded-xl text-center">
                    <img src="@/assets/Bonds/main-card-1.svg" class="px-1 pb-4" alt="main-card-1" width="50%" />
                    <p class="fs-16 font-weight-bold lh-16 px-4 mt-1 mb-0">Invest in Government Securities</p>
                  </v-card>
                </v-col>
                <v-col cols="4" class="pr-0">
                  <v-card width="100%" class="pt-8 pb-3 stk-land-crds rounded-xl text-center">
                    <img src="@/assets/Bonds/main-card-2.svg" class="px-1 pb-4" alt="main-card-2" width="50%" />
                    <p class="fs-16 font-weight-bold lh-16 px-4 mt-1 mb-0">Investing in Sovereign Gold Bonds is easy</p>
                  </v-card>
                </v-col>
                <v-col cols="4" class="pr-0">
                  <v-card width="100%" class="pt-8 pb-3 stk-land-crds rounded-xl text-center">
                    <img src="@/assets/Bonds/main-card-3.svg" class="px-1 pb-4" alt="main-card-3" width="50%" />
                    <p class="fs-16 font-weight-bold lh-16 px-4 mt-1 mb-0">
                      Checkout tax <br />
                      free bonds <br />
                      in market
                    </p>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <!-- <p class="title font-weight-bold mb-3">Top indices <span class="float-right"><v-btn to="/stocks/allindices"
                            text plain class="text-none">All indices</v-btn></span></p>
                <v-card class="d-inline-flex overflow-x-auto elevation-0 no-scroll mb-10" width="100%">
                    <v-card v-for="n in 10" :key="n" class="px-3 py-2 mr-4" min-width="130px" outlined>
                        <p class="subtitle-2 font-weight-bold mb-2">Nifty 50</p>
                        <v-divider color="#00000" class="pt-02 mb-3" width="30%"></v-divider>
                        <p class="fs-12 txt-666 font-weight-medium mb-0">₹18,264.40</p>
                        <p class="fs-12 txt-gre font-weight-bold mb-0">195.40 (+1.08%)</p>
                    </v-card>
                </v-card> -->
        <p class="title font-weight-bold mb-0 mb-md-3">Top Bonds</p>
        <v-chip-group row class="d-md-none">
          <v-chip @click="bondtype = b" :color="bondtype == b ? '#000' : 'subtext666'" :text-color="bondtype == b ? '#fff' : 'subtext666'" v-for="(t, b) in bondsitem" :outlined="bondtype != b" :key="b">
            {{ t }}
          </v-chip>
        </v-chip-group>
        <div class="default-tab">
          <v-tabs fixed v-model="bondtype" color="primary" class="d-none d-md-flex">
            <v-tab class="font-weight-bold subtitle-2 mb-0 text-none" v-for="(t, b) in bondsitem" :key="b">
              <v-badge :content="bondsdatas[b] ? bondsdatas[b].length : ''" :value="bondsdatas[b] ? bondsdatas[b].length : ''">
                <span>{{ t }}</span>
              </v-badge>
            </v-tab>
            <v-tabs-slider></v-tabs-slider>
          </v-tabs>
        </div>
        <v-data-table
          must-sort
          :sort-by="['']"
          :sort-desc="[false]"
          hide-default-footer
          fixed-header
          :loading="loading"
          class="rounded-lg overflow-y-auto d-none d-md-block"
          :headers="tradeheader"
          style="border-radius: 4px; border: 1px solid var(--outline)"
          height="calc(100vh - 180px)"
          :items="bondsdatas[bondtype]"
          :items-per-page="bondsdatas[bondtype] && bondsdatas[bondtype].length > 0 ? showtable : 0"
          item-key="Scheme_Code"
        >
          <template v-slot:body="{items, headers}">
            <tbody v-if="bondsdatas[bondtype] && bondsdatas[bondtype].length > 0">
              <!-- @click="setMFrowdata(item)" -->
              <tr class="table-row" v-for="(item, o) in items" :key="o">
                <td class="pos-rlt" cols="1">
                  <P class="fs-13 font-weight-medium mb-0 txt-000 table-hov-text mt-2">
                    {{ item.name ? item.name : "" }}
                  </P>
                  <v-chip-group column class="mb-1">
                    <v-chip color="secbg" x-small class="table-hov-prd" text-color="subtext" style="border-radius: 5px; padding: 10px 8px !important">
                      <span class="font-weight-medium fs-10">
                        {{ item.symbol ? item.symbol : "" }}
                      </span>
                    </v-chip>
                    <!-- <v-chip color="secbg" x-small class="table-hov-prd" text-color="subtext"
                                            style="border-radius: 5px; padding: 10px 8px !important;">
                                            <span class="font-weight-medium fs-10">
                                                {{ item.isin ? item.isin : '' }}
                                            </span>
                                        </v-chip> -->
                  </v-chip-group>

                  <div @click.stop class="pos-abs table-hov" style="top: 8px; right: 0">
                    <v-btn @click="uid ? bondOrder(item, bondtype) : $emit('gologin')" small class="elevation-0 rounded-pill text-none primary--text font-weight-bold mr-3 mt-3" color="#F1F3F8">Invest</v-btn>
                  </div>
                </td>
                <td>
                  <!-- <span class="font-weight-medium">{{ `${getOrdinal(item.biddingStartDate.slice(0,
                            2))} -
                                        ${getOrdinal(item.biddingEndDate.slice(4,
                                7))}${item.biddingEndDate.slice(7, 16)}` }}</span> -->
                  <v-list-item style="width: 220px" class="px-0">
                    <v-list-item-content>
                      <v-list-item-subtitle class="font-weight-medium txt-666 fs-10">Start date </v-list-item-subtitle>
                      <v-list-item-title class="font-weight-bold caption">{{ item.biddingStartDate }} </v-list-item-title>
                      <v-list-item-subtitle class="font-weight-medium fs-10">{{ item.dailyStartTime }} </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content class="text-right">
                      <v-list-item-subtitle class="font-weight-medium txt-666 fs-10">End date </v-list-item-subtitle>
                      <v-list-item-title class="font-weight-bold caption">{{ `${new Date(item.biddingEndDate).toISOString().slice(8, 10)}-${new Date(item.biddingEndDate).toISOString().slice(5, 7)}-${new Date(item.biddingEndDate).toISOString().slice(0, 4)}` }} </v-list-item-title>
                      <v-list-item-subtitle class="font-weight-medium fs-10">{{ item.dailyEndTime }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </td>
                <td class="text-right font-weight-medium">
                  ₹{{ item.lotSize ? item.lotSize : "0.00" }} <br /><span class="subtext--text font-weight-regular">{{ item.lotSize ? item.lotSize / 100 : "0" }} Qty.</span>
                </td>
                <!-- <td class="text-right font-weight-medium">₹{{ item.lotSize ? item.lotSize.toFixed(2) : '0.00' }}</td> -->
                <!-- <td class="text-right font-weight-medium">{{ item.faceValue ? item.faceValue : '0.00' }}</td> -->
                <!-- <td class="text-right font-weight-medium">₹{{ item.cutoffPrice ? item.cutoffPrice :
                            '0.00' }}</td> -->
                <td class="text-right font-weight-medium">{{ item.issueSize ? item.issueSize / 10000000 : "0.00" }} <span class="subtext--text font-weight-regular">Cr.</span></td>
              </tr>
              <tr v-if="showtable < bondsdatas[bondtype].length">
                <td :colspan="headers.length">
                  <v-btn color="primary" plain class="elevation-0 text-none font-weight-bold py-4" @click="showtable += 24" block>show more</v-btn>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <td :colspan="headers.length" class="text-center">
                <div class="mx-auto py-16 mt-16">
                  <img class="mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" />
                  <h4 class="txt-999 font-weight-regular caption">There is no {{ bondtype == 0 ? "G-SEC" : bondtype == 1 ? "T-BILL" : bondtype == 2 ? "SDL" : bondtype == 3 ? "SGB" : "" }} data here yet!</h4>
                </div>
              </td>
            </tbody>
          </template>
        </v-data-table>
        <div class="d-md-none overflow-hidden">
          <v-divider></v-divider>
          <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
          <div v-if="bondsdatas[bondtype] && bondsdatas[bondtype].length > 0">
            <v-card v-for="(item, o) in bondsdatas[bondtype].length < showtable ? bondsdatas[bondtype].slice(0, showtable) : bondsdatas[bondtype]" :key="o" class="elevation-0 rounded-0 overflow-hidden">
              <div class="px-0">
                <v-list-item class="pa-0">
                  <v-list-item-content class="pa-0 pt-1">
                    <v-list-item-title class="fs-13 font-weight-bold mb-0 txt-000 table-hov-text mt-2">
                      {{ item.name ? item.name : "" }}
                    </v-list-item-title>
                    <v-chip-group column class="mb-1">
                      <v-chip color="secbg" x-small class="table-hov-prd" text-color="subtext" style="border-radius: 5px; padding: 10px 8px !important">
                        <span class="font-weight-medium fs-10">
                          {{ item.symbol ? item.symbol : "" }}
                        </span>
                      </v-chip>
                      <v-chip class="px-0 crd-trn" x-small text-color="#000">
                        {{
                          `${item.biddingStartDate} ${item.dailyStartTime.slice(0, 5)} to
                                                ${item.biddingEndDate.slice(4, 16)} ${item.dailyEndTime.slice(0, 5)}`
                        }}
                        <!-- {{ `${getOrdinal(item.biddingStartDate.slice(0, 2))} ${item.dailyStartTime.slice(0,5)} -
                                                ${getOrdinal(item.biddingEndDate.slice(4,7))}${item.biddingEndDate.slice(7, 11)} ${item.dailyEndTime.slice(0,5)}`  }} -->
                      </v-chip>
                    </v-chip-group>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-row class="py-2" no-glutters>
                  <v-col cols="6">
                    <p class="mb-1 fs-12 txt-666">
                      Min. qty: <span class="font-weight-bold black--text">{{ item.lotSize ? item.lotSize / 100 : "0" }}</span>
                    </p>
                    <p class="mb-1 fs-12 txt-666">
                      Min. Inv: <span class="font-weight-bold black--text">₹{{ item.lotSize ? item.lotSize : "0.00" }}</span>
                    </p>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <p class="mb-1 fs-12 txt-666">Lot size</p>
                    <p class="mb-1 fs-12 font-weight-bold black--text">{{ item.issueSize ? item.issueSize / 10000000 : "0.00" }} <span class="subtext--text font-weight-regular">Cr.</span></p>
                  </v-col>
                </v-row>
              </div>
              <div class="px-4">
                <v-btn block @click="uid ? bondOrder(item, bondtype) : $emit('gologin')" small class="elevation-0 rounded-pill text-none primary--text font-weight-bold mb-2" color="secbg">Invest</v-btn>
              </div>
              <v-card v-if="uid && showtable - 1 != o" class="pt-1 rounded-0 elevation-0" color="secbg"></v-card>
            </v-card>
          </div>
          <v-card v-else class="elevation-0 rounded-0 text-center">
            <div class="mx-auto py-8">
              <img class="align-self-stretch mx-auto" width="70px" :src="require('@/assets/no data folder.svg')" />
              <h4 class="txt-999 font-weight-regular fs-14">There is no data here yet!</h4>
            </div>
          </v-card>
          <v-btn v-if="bondsdatas[bondtype] && showtable < bondsdatas[bondtype].length" color="primary" plain class="elevation-0 text-none font-weight-bold py-4 mb-1" @click="showtable += 24" block>show more</v-btn>
        </div>
      </v-col>
      <v-col cols="3" class="d-none"></v-col>
    </v-row>
  </div>
</template>

<script>
import eventBus from "../../../eventBus.js";
// import { getBondGsec, getBondTbill, getBondSdl, getBondSgb } from '@/components/mixins/getAPIdata';

export default {
  data: () => ({
    bondtype: 0,
    bondsdatas: [],
    loading: true,
    opensearch: null,
    showtable: 24,
    uid: null,

    bondsitem: ["Govt. bonds", "Treasury bonds", "State bonds", "Sovereign Gold bonds"],
  }),

  computed: {
    tradeheader() {
      return [
        {text: "Bonds name", value: "name", sortable: false, width: "36%"},
        {text: "BID date", value: "date", sortable: false},
        {text: "Min. Invest", value: "minPrice", sortable: false, align: "right"},
        // { text: 'FV', value: 'faceValue', sortable: false, align: 'right' },
        // { text: 'Cutoff Price', value: 'cutoffPrice', sortable: false, align: 'right' },
        {text: "Lot Size", value: "lotSize", sortable: false, align: "right"},

        // { text: 'Frequency', value: 's_prdt_ali', sortable: false }
      ];
    },
  },
  created() {
    this.token = "";
    this.uid = "";
  },

  async mounted() {
    eventBus.$emit("tabBar-load");
    eventBus.$emit("login-event");
    this.$emit("receive", "bonds");
    eventBus.$on("setRec-event", (value) => {
      if (value == "stat_ok") {
        this.$emit("receive", "bonds");
      } else if (value && Object.entries(value).length > 0) {
        this.getBondsdata(value);
      }
    });
    eventBus.$on("user-event", () => {
      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        this.token = sessionStorage.getItem("usession");
        this.uid = sessionStorage.getItem("userid");
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("setRec-event");
  },
  methods: {
    getBondsdata(receive) {
      this.bondsdatas["0"] = receive.gsec && receive.gsec.NCBGsec ? receive.gsec.NCBGsec : [];
      this.bondsdatas["1"] = receive.tbill && receive.tbill.NCBTBill ? receive.tbill.NCBTBill : [];
      this.bondsdatas["2"] = receive.sdl && receive.sdl.NCBSDL ? receive.sdl.NCBSDL : [];
      this.bondsdatas["3"] = receive.sgb && receive.sgb.SGB ? receive.sgb.SGB : [];
      this.loading = false;
    },
    bondOrder(item, type) {
      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        item["minbidqty"] = item.minBidQuantity / 100;
        item["maxbidqty"] = item.maxQuantity / 100;
        item["lotbitsize"] = item.lotSize / 100;
        this.$emit("menudialog", "bondorder", item, type);
      } else {
        this.token = "";
        this.uid = "";
        this.$emit("gologin");
      }
    },
    getOrdinal(n) {
      let ord = "th";
      if (n % 10 == 1 && n % 100 != 11) {
        ord = "st";
      } else if (n % 10 == 2 && n % 100 != 12) {
        ord = "nd";
      } else if (n % 10 == 3 && n % 100 != 13) {
        ord = "rd";
      }
      return `${n}${ord}`;
    },
  },
};
</script>

<style>
.stk-land-crds {
  background-color: linear-gradient(270deg, #fff 77.04%, #f6f6f6 115%), #fff !important;
  box-shadow: 0px 38.519px 25.482px 0px rgba(83, 30, 0, 0.04), 0px 20px 13px 0px rgba(83, 30, 0, 0.04), 0px 8.148px 6.519px 0px rgba(83, 30, 0, 0.03), 0px 1.852px 3.148px 0px rgba(83, 30, 0, 0.02) !important;
}
</style>
