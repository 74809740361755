<template>
  <div>
    <div :id="containerId" />
    <v-card v-if="showchart" color="cardbg" width="100%" id="chartloader" class="pos-abs elevation-0 rounded-0 text-center" :style="{top: 0, left: 0}">
      <v-progress-circular class="my-auto" color="primary" :size="60" :width="4" indeterminate :style="{top: '40%'}"></v-progress-circular>
    </v-card>
  </div>
</template>

<script>
import {widget} from "../../public/charting_library";
import Datafeed from "../components/mixins/feedFactory.js";
import eventBus from "../eventBus.js";

export default {
  name: "TVChartContainer",

  props: {
    symbol: {
      default: localStorage.getItem("ssdtsym"),
      type: String,
    },
    // interval: {
    //   default: "1",
    //   type: String,
    // },
    containerId: {
      default: "tv_chart_container",
      type: String,
    },
    datafeedUrl: {
      default: "",
      type: String,
    },
    libraryPath: {
      default: "../charting_library/",
      type: String,
    },
    chartsStorageUrl: {
      default: "https://chartbe.mynt.in",
      type: String,
    },
    chartsStorageApiVersion: {
      default: "1.1",
      type: String,
    },
    clientId: {
      default: "ZEBU",
      type: String,
    },
    userId: {
      default: "",
      type: String,
    },
    fullscreen: {
      default: false,
      type: Boolean,
    },
    autosize: {
      default: true,
      type: Boolean,
    },
    studiesOverrides: {
      type: Object,
    },
    title: String,
    subtitle: String,
    text: String,
    image: String,
  },
  tvWidget: null,

  data() {
    return {
      showchart: true,
      theme: "light",
      savedSymbol: "",
      settings: JSON.parse(localStorage.getItem("settings")),
      interval: "1",
    };
  },

  mounted() {
    this.interval = this.settings && this.settings["chart.lastUsedTimeBasedResolution"] ? this.settings["chart.lastUsedTimeBasedResolution"] : "5";
    this.initTWChart(localStorage.getItem("adv_chart"));

    var sss = [];
    if (this.settings) {
      for (let i in this.settings) {
        if (i.includes("savedwatch")) {
          delete this.settings[i];
        }
      }
      sss = this.settings;
    } else {
      sss = {};
    }
    localStorage.setItem("settings", JSON.stringify(sss));

    eventBus.$on("ssd-event", (type, token, exch, tsym) => {
      window.tvWidget.activeChart().setSymbol(`${exch}:${tsym}`);
    });
    //  window.tvWidget.activeChart().setSymbol(`NSE:Nifty Bank`);
  },
  beforeDestroy() {
    // eventBus.$off('ssd-event');
  },

  methods: {
    initTWChart(type) {
      let sym = localStorage.getItem("ssdtsym");
      sym = sym ? sym : this.symbol;
      const widgetOptions = {
        symbol: sym,
        datafeed: Datafeed,
        interval: this.interval,
        // timeframe: "5D",
        container: this.containerId,
        auto_save_delay: 1,
        library_path: this.libraryPath,
        timezone: "Asia/Kolkata",
        locale: "en",
        custom_css_url: "../css/style.css",
        // load_last_chart: true,
        disabled_features: [
          "context_menus",

          "show_interval_dialog_on_key_press",
          "header_screenshot",
          "header_symbol_search",
          "header_quick_search",
          "symbol_search_hot_key",
          "adaptive_logo",
          "border_around_the_chart",
          // "chart_property_page_right_margin_editor",
          "control_bar",
          "countdown",
          // "go_to_date",
          // "hide_main_series_symbol_from_indicator_legend",
          "items_favoriting",
          "left_toolbar",
          "object_tree_legend_mode",
          "property_pages",
          "popup_hints",
          "remove_library_container_border",
          "show_chart_property_page",
          "show_right_widgets_panel_by_default",
          "show_symbol_logo_for_compare_studies",
          "show_symbol_logo_in_legend",
          "show_object_tree",
          "source_selection_markers",
          "symbol_info",
          "accessibility",
          "auto_enable_symbol_labels",
          "constraint_dialogs_movement",
          // "cropped_tick_marks",
          "datasource_copypaste",
          "insert_indicator_dialog_shortcut",
          "legend_inplace_edit",
          "right_bar_stays_on_scroll",
          "save_shortcut",

          "watchlist_import_export",
          "right_toolbar",
          "trading_account_manager",
          "add_to_watchlist",
          "multiple_watchlists",
          // "keep_object_tree_widget_in_right_toolbar",
          "hide_right_toolbar",
          "order_panel",
          "order_info",
          // "display_legend_on_all_charts",

          // "chart_property_page_scales",
          // "clear_price_scale_on_error_or_empty_bars",
          // "display_market_status",
          // "header_saveload",

          // "legend_widget",
          // "scales_date_format",
          // "scales_time_hours_format",
          // "horz_touch_drag_scroll",
          // "chart_scroll",

          // "mouse_wheel_scroll",
          // "mouse_wheel_scale",
          // "pinch_scale",
          // "pressed_mouse_move_scroll",
          // "scales_context_menu",

          // "header_chart_type",
          // "header_layouttoggle",
          // "header_indicators",
          // "header_resolutions",
          // "header_settings",
          // "header_undo_redo",
          // "header_compare",
          // "create_volume_indicator_by_default_once",
          // "create_volume_indicator_by_default",
          // "vert_touch_drag_scroll",

          // "pane_context_menu",
          // "edit_buttons_in_legend",
          // "delete_button_in_legend",
          // "format_button_in_legend",
          // "show_hide_button_in_legend",
          // "header_fullscreen_button",
        ],

        enabled_features: ["use_localstorage_for_settings", "iframe_loading_compatibility_mode", "iframe_loading_compatibility_mode"],
        charts_storage_api_version: this.chartsStorageApiVersion,
        client_id: this.clientId,
        user_id: this.userId,
        fullscreen: this.fullscreen,
        autosize: this.autosize,
        // debug:true,
        studies_overrides: this.studiesOverrides,
        theme: this.$vuetify.theme.dark ? "light" : "dark",
        settings_adapter: {
          initialSettings: this.settings,
          setValue: function (key, value) {
            let settings;
            if (localStorage.getItem("settings") != undefined) {
              settings = JSON.parse(localStorage.getItem("settings"));
              if (settings[key]) {
                settings[key] = value;
              } else {
                settings[key] = value;
              }
            } else {
              settings[key] = value;
            }
            localStorage.setItem("settings", JSON.stringify(settings));
          },
          removeValue: function (key) {
            let settings = JSON.parse(localStorage.getItem("settings"));
            delete settings[key];
            localStorage.setItem("settings", JSON.stringify(settings));
          },
        },
        // right side widget to watchlist
        widgetbar: {
          details: false,
          news: false,
          watchlist: false,
          datawindow: false,
          watchlist_settings: {
            default_symbols: [],
            readonly: false,
          },
        },
      };
      var idx = document.getElementById("tv_chart_container").classList;
      var cht = document.getElementById("chartloader").classList;

      if (type == "0") {
        widgetOptions.disabled_features.push("header_widget");
        widgetOptions.disabled_features.push("timeframes_toolbar");
        widgetOptions.disabled_features.push("legend_context_menu");
        widgetOptions.disabled_features.push("shift_visible_range_on_new_bar");
        widgetOptions.disabled_features.push("uppercase_instrument_names");
        widgetOptions.disabled_features.push("main_series_scale_menu");

        idx.add("TVChartpop");
        idx.remove("TVChartContainer");
        cht.add("TVChartpop");
        cht.remove("TVChartContainer");
      } else {
        idx.add("TVChartContainer");
        idx.remove("TVChartpop");
        cht.add("TVChartContainer");
        cht.remove("TVChartpop");
      }
      const tvWidget = new widget(widgetOptions);
      this.tvWidget = tvWidget;
      window.tvWidget = tvWidget;

      tvWidget.onChartReady(() => {
        tvWidget.changeTheme(this.$vuetify.theme.dark ? "dark" : "light");
        setTimeout(() => {
          tvWidget.activeChart().applyOverrides({
            "paneProperties.background": this.$vuetify.theme.dark ? "#121212" : "#ffffff",
            "paneProperties.backgroundGradientEndColor": this.$vuetify.theme.dark ? "#121212" : "#ffffff",
            "paneProperties.backgroundGradientStartColor": this.$vuetify.theme.dark ? "#121212" : "#ffffff",

            // "paneProperties.horzGridProperties.color": this.$vuetify.theme.dark ? "#131722" : "rgba(42, 46, 57, 0.06)",
            // "paneProperties.vertGridProperties.color": this.$vuetify.theme.dark ? "#131722" : "rgba(42, 46, 57, 0.06)",
            // "scalesProperties.lineColor": this.$vuetify.theme.dark ? "#131722" : "rgba(42, 46, 57, 0)",
            // "scalesProperties.textColor": this.$vuetify.theme.dark ? "#B2B5BE" : "#131722",
            // ---

            "mainSeriesProperties.highLowAvgPrice.highLowPriceLinesVisible": true,
            "mainSeriesProperties.highLowAvgPrice.highLowPriceLabelsVisible": true,

            "scalesProperties.showStudyPlotLabels": false,
            "mainSeriesProperties.showCountdown": true,
            // "paneProperties.legendProperties.showSeriesTitle": false,
            "paneProperties.legendProperties.showStudyArguments": false,
            "mainSeriesProperties.statusViewStyle.showExchange": false,
            "mainSeriesProperties.statusViewStyle.showInterval": false,
          });
        }, 0);
        if (type != "0") {
          tvWidget.headerReady().then(() => {
            const refreshButton = tvWidget.createButton({align: "right"});
            refreshButton.setAttribute("title", "Reset Chart");
            refreshButton.innerHTML =
              '<svg width="28" height="34" viewBox="0 0 28 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 19C5.5 20.6811 5.99852 22.3245 6.93251 23.7223C7.8665 25.1202 9.19402 26.2096 10.7472 26.853C12.3004 27.4963 14.0094 27.6646 15.6583 27.3367C17.3071 27.0087 18.8217 26.1992 20.0104 25.0104C21.1992 23.8217 22.0087 22.3071 22.3367 20.6583C22.6647 19.0094 22.4963 17.3004 21.853 15.7472C21.2096 14.194 20.1202 12.8665 18.7223 11.9325C17.3245 10.9985 15.6811 10.5 14 10.5H7.5" stroke="currentColor"/><path d="M11 14L7.5 10.5L11 7" stroke="currentColor"/></svg>';

            refreshButton.addEventListener("click", () => {
              tvWidget.activeChart().resetData();
              tvWidget.activeChart().executeActionById("chartReset");
            });
          });
        }

        tvWidget.activeChart().dataReady(() => {
          tvWidget.activeChart().executeActionById("chartReset");
        });
        this.showchart = false;
      });
    },
  },

  destroyed() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.TVChartContainer {
  height: calc(100vh - 82px) !important;
}
.TVChartpop {
  height: 204px !important;
  zoom: 90% !important;
}
</style>
