<template>
  <div>
    <v-tabs-items v-model="bodytab">
      <v-tab-item>
        <StocksOrderBook />
      </v-tab-item>
      <v-tab-item>
        <StocksTradeBook />
      </v-tab-item>
      <v-tab-item>
        <StockGTTorders />
      </v-tab-item>
      <v-tab-item>
        <StockBSKorders />
      </v-tab-item>
      <v-tab-item>
        <StockSIPorders />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import StocksOrderBook from './StocksOrderBook.vue';
import StockGTTorders from './StockGTTorders.vue';
import StocksTradeBook from './StocksTradeBook.vue';
import StockBSKorders from './StockBSKorders.vue';
import StockSIPorders from './StockSIPorders.vue';

import eventBus from '../../eventBus.js';

export default {
  data: () => ({
    bodytab: 0,
    dashitems: [
      { tab: true, key: 0, txt: 'Orders Book' },
      { tab: true, key: 1, txt: 'Trade Book' },
      { tab: true, key: 2, txt: 'GTT Order' },
      { tab: true, key: 3, txt: 'Basket Order' },
      { tab: true, key: 4, txt: 'SIP Order' },
    ],
    uid: null,
    stoken: null,
    mtoken: null,
  }),
  created() {

  },
  computed: {

  },
  mounted() {
    this.$emit('receive', 'stk');
    eventBus.$emit('login-event');

    eventBus.$on('user-event', () => {
      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        this.mtoken = sessionStorage.getItem("msession");
        this.stoken = sessionStorage.getItem("usession");
        this.uid = sessionStorage.getItem("userid");
      } else {
        this.$router.push('/')
      }
    })
    eventBus.$on('order-tab', (tab) => {
      this.bodytab = tab
    })
  },
  beforeDestroy() {
    eventBus.$off('user-event');
  },

  methods: {

  },
  components: { StocksOrderBook, StockGTTorders, StocksTradeBook, StockBSKorders, StockSIPorders }
}
</script>
