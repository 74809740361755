
// import mynturl from '../../apiurl'

export var userid;
export var usession;
seyCheckwebsocket();
// import Datafeed from './feedFactory.js'
// var watchlistapi = mynturl.myntapi + "MarketWatch"

function seyCheckwebsocket() {
    userid = sessionStorage.getItem('userid')
    usession = sessionStorage.getItem('msession')
}

export async function makeApiRequest(path, request) {
    try {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            redirect: 'follow',
            headers: myHeaders,
            body: request
        };
        const response = await fetch(path, requestOptions);
        // console.log("response : ",response)
        // if (!response.ok) {
        return response.json();
        // }



    } catch (error) {
        throw new Error(`zebull symbols request error: ${error.status}`);
    }
}

export async function fetchfromZebullAPI(path, requestOptions) {
    try {
        const response = await fetch(path, requestOptions);
        let resp = response.json();
        return resp
    } catch (error) {
        throw new Error(`zebull fetch request error: ${error.status}`);
    }
}

// export async function getWatchlistdata(watchlistID, index) {
//     var dataArr = []

//     let myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");
//     let watchlistName = `jData={"uid":"${userid}","wlname":"${watchlistID}"}&jKey=${usession}`
//     let indexwl = `jData={"uid":"${userid}","exch":"NSE"}&jKey=${usession}`
//     var requestOptions = {
//         method: 'POST',
//         redirect: 'follow',
//         headers: myHeaders,
//         body: index == true ? indexwl : watchlistName
//     };

//     var response = index == true ? await fetchfromZebullAPI(mynturl.myntapi + "GetIndexList", requestOptions) : await fetchfromZebullAPI(watchlistapi, requestOptions)
//     if (response.values[0] !== "No Market Watch") {
//         for (let scripts in response.values) {
//             let script = response.values[scripts]
//             let resolve = await Datafeed.resolveSymbol(index == true ? `NSE:${script.idxname.toUpperCase()}` : `${script.exch}:${script.tsym.toUpperCase()}`, resolvedSym, notresolvedSym)
//             if (resolve != undefined) {
//                 var quote = {
//                     s: 'ok',
//                     n: index == true ? `NSE:${script.idxname.toUpperCase()}` : `${script.exch}:${script.tsym.toUpperCase()}`,
//                     v: {
//                         ch: "",
//                         chp: "",
//                         short_name: index == true ? script.idxname.toUpperCase() : script.tsym.toUpperCase(),
//                         exchange: index == true ? "NSE" : script.exch,
//                         description: index == true ? script.idxname.toUpperCase() : script.tsym.toUpperCase(),
//                         lp: "",
//                         ask: "",
//                         ask_qty: "",
//                         bid: "",
//                         bid_qty: "",
//                         open_price: "",
//                         high_price: "",
//                         low_price: "",
//                         prev_close_price: "",
//                         volume: ""
//                     }
//                 }
//                 dataArr.push(quote)
//             }
//         }
//     }
//     return dataArr
// }

// export async function getCustomWatchlistdata(exch, type, index) {
//     var dataArr = []
//     let myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");
//     if (type == "top") {
//         let indexwl;
//         if (index == 0) indexwl = `jData={"uid":"${userid}","exch":"${exch}","tb":"T","bskt":"${exch}ALL","crt":"LTP"}&jKey=${usession}`
//         if (index == 1) indexwl = `jData={"uid":"${userid}","exch":"${exch}","tb":"B","bskt":"${exch}ALL","crt":"LTP"}&jKey=${usession}`
//         if (index == 2) indexwl = `jData={"uid":"${userid}","exch":"${exch}","tb":"T","bskt":"${exch}ALL","crt":"VOLUME"}&jKey=${usession}`
//         if (index == 3) indexwl = `jData={"uid":"${userid}","exch":"${exch}","tb":"T","bskt":"${exch}ALL","crt":"VALUE"}&jKey=${usession}`
//         var requestOptions = {
//             method: 'POST',
//             redirect: 'follow',
//             headers: myHeaders,
//             body: indexwl
//         };
//         var response = await fetchfromZebullAPI(mynturl.myntapi + "TopList", requestOptions)
//         if (response.stat !== "Not_Ok") {
//             for (let scripts in response.values) {
//                 let script = response.values[scripts]
//                 let resolve = Datafeed.resolveSymbol(index == true ? `NSE:${script.idxname.toUpperCase()}` : `${script.exch}:${script.tsym.toUpperCase()}`, resolvedSym, notresolvedSym)
//                 if (resolve != undefined) {
//                     var quote = {
//                         s: 'ok',
//                         n: `${script.exch}:${script.tsym.toUpperCase()}`,
//                         v: {
//                             ch: "",
//                             chp: "",
//                             short_name: script.tsym.toUpperCase(),
//                             exchange: script.exch,
//                             description: script.tsym.toUpperCase(),
//                             lp: "",
//                             ask: "",
//                             ask_qty: "",
//                             bid: "",
//                             bid_qty: "",
//                             open_price: "",
//                             high_price: "",
//                             low_price: "",
//                             prev_close_price: "",
//                             volume: ""
//                         }
//                     }
//                     dataArr.push(quote)
//                 }
//             }
//         }
//     }
//     else {
//         let indexwl = `jData={"uid":"${userid}","actid":"${userid}","prd":"C"}&jKey=${usession}`
//         requestOptions = {
//             method: 'POST',
//             redirect: 'follow',
//             headers: myHeaders,
//             body: indexwl
//         };
//         response = await fetchfromZebullAPI(mynturl.myntapi + "Holdings", requestOptions)
//         if (response[0]) {
//             if (response[0].stat == "Ok") {
//                 for (let scripts in response) {
//                     let script = response[scripts];
//                     let resolve = Datafeed.resolveSymbol(index == true ? `NSE:${script.idxname.toUpperCase()}` : `${script.exch_tsym[0].exch}:${script.exch_tsym[0].tsym.toUpperCase()}`, resolvedSym, notresolvedSym)
//                     if (resolve != undefined) {
//                         let exchsym = script.exch_tsym[0];
//                         // console.log("SCRIPTSSSSSSSS", response, scripts, script, exchsym)
//                         quote = {
//                             s: 'ok',
//                             n: `${exchsym.exch}:${exchsym.tsym.toUpperCase()}`,
//                             v: {
//                                 ch: "",
//                                 chp: "",
//                                 short_name: exchsym.tsym.toUpperCase(),
//                                 exchange: exchsym.exch,
//                                 description: exchsym.tsym.toUpperCase(),
//                                 lp: "",
//                                 ask: "",
//                                 ask_qty: "",
//                                 bid: "",
//                                 bid_qty: "",
//                                 open_price: "",
//                                 high_price: "",
//                                 low_price: "",
//                                 prev_close_price: "",
//                                 volume: ""
//                             }
//                         }
//                         dataArr.push(quote)
//                     }
//                 }
//             }
//         }
//     }
//     return dataArr
// }

// export async function updateWatchlistApi(watchlistTitle, watchlistSymbols) {
//     let myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");
//     let watchlistData = `jData={"uid":"${userid}","wlname":"${watchlistTitle}","scrips":"${watchlistSymbols}"}&jKey=${usession}`
//     var requestOptions = {
//         method: 'POST',
//         redirect: 'follow',
//         headers: myHeaders,
//         body: watchlistData
//     };

//     var response = await fetchfromZebullAPI(mynturl.myntapi + "AddMultiScripsToMW", requestOptions)
//     return response
// }

// export async function getNfoscripts() {
//     let myHeaders = new Headers();

//     var requestOptions = {
//         method: 'POST',
//         redirect: 'follow',
//         headers: myHeaders,
//     };
//     var response = await fetchfromZebullAPI(apiurl.zebuApiUrl + "NFOsymbol", requestOptions);
//     let datas = []
//     for (let s = 0; s < response.length; s++) {
//         if (response[s].Symbol != 'MIDCPNIFTY') {
//             response[s]['idx'] = s;
//             response[s].Symbol = response[s].Symbol == 'FINNIFTY' ? 'FINNIFTY' : response[s].Symbol == 'NIFTY' ? 'NIFTY 50' : response[s].Symbol == 'BANKNIFTY' ? 'NIFTY BANK' : `${response[s].Symbol}-EQ`
//             datas.push(response[s])
//         }
//     }
//     return datas
// }

// export async function removeWatchlistApi(watchlistTitle, watchlistSymbols) {
//     let myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");
//     let watchlistData = `jData={"uid":"${userid}","wlname":"${watchlistTitle}","scrips":"${watchlistSymbols}"}&jKey=${usession}`
//     var requestOptions = {
//         method: 'POST',
//         redirect: 'follow',
//         headers: myHeaders,
//         body: watchlistData
//     };

//     var response = await fetchfromZebullAPI(mynturl.myntapi + "DeleteMultiMWScrips", requestOptions)
//     return response
// }

// function resolvedSym(tt) {
//     return tt
// }

// function notresolvedSym(tt) {
//     return tt
// }
