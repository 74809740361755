<template>
  <div>
    <div style="position: sticky !important; top: 68px; z-index: 1">
      <v-toolbar class="tool-sty elevation-0 pl-4 d-none d-sm-block" height="40px" color="cardbg" dense>
        <v-tabs fixed color="maintext" v-model="bodytab" show-arrows dense>
          <template v-for="(t, index) in dashitems">
            <v-tab :key="index" v-if="t.tab" class="text-none text-start min-w-fit">
              <span class="text-center tab-txt">{{ t.txt }}</span>
            </v-tab>
          </template>
          <v-tabs-slider class="" color="maintext"></v-tabs-slider>
        </v-tabs>
        <v-spacer></v-spacer>
        <v-select @change="switchbus.$emit('order-tab', filter)" v-if="bodytab == 0" style="max-width: 180px" v-model="filter" hide-details append-icon="mdi-chevron-down" class="rounded-pill" dense flat solo background-color="secbg" item-text="txt" item-value="key" :items="dashoptions"></v-select>
      </v-toolbar>
      <v-toolbar class="tool-sty elevation-0 d-sm-none" height="40px" color="cardbg" dense>
        <v-tabs fixed-tabs color="maintext" v-model="mbodytab" show-arrows dense>
          <template v-for="(t, index) in Mdashitems">
            <v-tab :key="index" v-if="t.tab" class="text-none text-start">
              <span class="text-center tab-txt">{{ t.txt }}</span>
            </v-tab>
          </template>
          <v-tabs-slider color="maintext"></v-tabs-slider>
        </v-tabs>
      </v-toolbar>
      <v-divider></v-divider>
    </div>
    <v-tabs-items v-model="bodytab" class="pt-4 d-none d-sm-block">
      <v-tab-item>
        <StockOrderSrc />
      </v-tab-item>
      <v-tab-item>
        <MutualOrderbook />
      </v-tab-item>
      <v-tab-item>
        <IpoOrderbook />
      </v-tab-item>
      <v-tab-item>
        <BondsOrderbook />
      </v-tab-item>
    </v-tabs-items>
    <v-tabs-items v-model="mbodytab" class="pt-4 d-sm-none">
      <v-tab-item>
        <MutualOrderbook />
      </v-tab-item>
      <v-tab-item>
        <IpoOrderbook />
      </v-tab-item>
      <v-tab-item>
        <BondsOrderbook />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import StockOrderSrc from "../../views/Orders/StockOrderSrc.vue";
import MutualOrderbook from "../Dashboard/mutualfund/MutualOrderbook.vue";
import IpoOrderbook from "../Dashboard/ipos/IpoOrderbook.vue";
import BondsOrderbook from "../Dashboard/bonds/BondsOrderbook.vue";

import eventBus from "../../eventBus.js";

export default {
  data: () => ({
    filter: 0,
    bodytab: 0,
    mbodytab: 0,
    dashitems: [
      {tab: true, txt: "Stocks"},
      {tab: true, txt: "Mutual funds"},
      {tab: true, txt: "IPOs"},
      {tab: true, txt: "Bonds"},
    ],
    Mdashitems: [
      // {tab: true, txt: "Stocks"},
      {tab: true, txt: "Mutual funds"},
      {tab: true, txt: "IPOs"},
      {tab: true, txt: "Bonds"},
    ],

    dashoptions: [
      {tab: true, key: 0, txt: "Orders Book"},
      {tab: true, key: 1, txt: "Trade Book"},
      {tab: true, key: 2, txt: "GTT Order"},
      {tab: true, key: 3, txt: "Basket Order"},
      {tab: true, key: 4, txt: "SIP Order"},
      // { tab: true, key: 5, txt: 'XSIP' },
    ],

    uid: null,
    stoken: null,
    mtoken: null,

    switchbus: null,
  }),
  created() {
    let params = this.$route.params;
    if (params && params.tsym) {
      this.bodytab = 0;
      this.filter = 4;
      eventBus.$emit("order-tab", 4);
      setTimeout(() => {
        eventBus.$emit("siporder-trigger", params);
      }, 10);
    }
    this.switchbus = eventBus;
  },
  computed: {},
  mounted() {
    this.$emit("receive", "stk");
    eventBus.$emit("login-event");

    eventBus.$on("user-event", () => {
      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        this.mtoken = sessionStorage.getItem("msession");
        this.stoken = sessionStorage.getItem("usession");
        this.uid = sessionStorage.getItem("userid");
      } else {
        this.$router.push("/");
      }
    });

    eventBus.$on("siporder-trigger", (item, type) => {
      this.bodytab = 0;
      this.filter = 4;
      eventBus.$emit("order-tab", 4);
      if (!type) {
        setTimeout(() => {
          eventBus.$emit("siporder-trigger", item, true);
        }, 10);
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("user-event");
    eventBus.$off("siporder-trigger");
  },

  methods: {},
  components: {StockOrderSrc, MutualOrderbook, IpoOrderbook, BondsOrderbook},
};
</script>
