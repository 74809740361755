<template>
  <div>
    <v-breadcrumbs class="pa-0 pt-2" :items="bcitems">
      <template v-slot:item="{item}">
        <v-breadcrumbs-item :class="!item.disabled ? 'primary--text cursor-p' : ''" @click="$router.push(item.href)" :disabled="item.disabled">
          {{ item.text == "exch" ? exchtype : item.text }}
        </v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-toolbar flat dense class="tool-sty crd-trn">
      <span class="title font-weight-bold">All Indices</span>
      <v-spacer></v-spacer>

      <div class="mr-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
          <path d="M1.5 2.5H11.5" stroke="#0037B7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M14.5 2.5H16.5" stroke="#0037B7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M14.5 6.5H16.5" stroke="#0037B7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M14.5 10.5H16.5" stroke="#0037B7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M14.5 14.5H16.5" stroke="#0037B7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M1.5 6.5H7.5" stroke="#0037B7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M1.5 10.5H11.5" stroke="#0037B7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M1.5 14.5H6.5" stroke="#0037B7" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <span class="subtitle-1 font-weight-bold">{{ totalcount }} Indices</span>
    </v-toolbar>
    <p class="fs-12 subtext--text mb-10">
      Stock market indexes represent a specific group of shares selected based on criteria like trading frequency and share size. These indexes serve as a way to track market direction and changes. serve as statistical indicators that measure fluctuations in financial markets. These indices provide
      performance insights for specific market segments or the overall market.
    </p>
    <div>
      <v-toolbar class="tool-sty elevation-0 mb-4 crd-trn" dense>
        <p class="font-weight-bold mb-0">{{ exchtype }} {{ exchcount[exchtype] ? exchcount[exchtype] : "0" }} indices</p>

        <v-spacer></v-spacer>

        <v-select
          @change="setTabchange()"
          style="max-width: 180px"
          v-model="exchtype"
          hide-details
          append-icon="mdi-chevron-down"
          item-text="txt"
          item-value="val"
          prepend-inner-icon="mdi-playlist-check"
          class="rounded-pill mr-3"
          dense
          flat
          solo
          background-color="secbg"
          :items="['NSE', 'BSE', 'MCX']"
          label="Filter"
        ></v-select>

        <v-text-field style="max-width: 220px" :disabled="isloading" v-model="opensearch" hide-details prepend-inner-icon="mdi-magnify" label="Search for Indices" class="rounded-pill d-none d-sm-flex" dense flat solo background-color="secbg"></v-text-field>
      </v-toolbar>
      <v-data-table must-sort disable-sort fixed-header mobile-breakpoint :loading="isloading" class="rounded-lg overflow-y-auto mb-6" style="border-radius: 4px; border: 1px solid var(--outline)" :headers="indiceheader" :search="opensearch" :items="tablesecdata" :items-per-page="10">
        <template v-slot:[`item.tsym`]="{item}">
          <span @click="setSinglestock(item.tsym.split('-')[0], item)" class="font-weight-medium text-capitalize txt-dec-cust ws-p">{{ item.tsym }}</span>
        </template>

        <template v-slot:[`item.ltp`]="{item}">
          <p class="mb-0 lh-18">
            <span class="font-weight-medium maintext--text">₹<span :id="`indi${item.token}ltp`" v-text="item.ltp ? Number(item.ltp).toFixed(2) : '0.00'"></span></span> <br />
            <span class="font-weight-medium fs-12 ws-p" :id="`indi${item.token}chpclr`" :class="item.ch > 0 ? 'maingreen--text' : item.ch < 0 ? 'mainred--text' : 'subtext--text'">
              <span :id="`indi${item.token}ch`" v-text="item.ch ? item.ch : '0.00'"> </span>
              <span :id="`indi${item.token}chp`" v-text="` (${item.chp ? item.chp : '0.00'}%)`"></span
            ></span>
          </p>
        </template>

        <template v-slot:[`item.vol`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`indi${item.token}vol`">{{ item.vol ? item.vol : "0.00" }}</span>
        </template>
        <template v-slot:[`item.op`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`indi${item.token}op`">{{ item.op ? item.op : "0.00" }}</span>
        </template>
        <template v-slot:[`item.cp`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`indi${item.token}cp`">{{ item.cp ? item.cp : "0.00" }}</span>
        </template>
        <template v-slot:[`item.high`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`indi${item.token}high`">{{ item.high ? item.high : "0.00" }}</span>
        </template>
        <template v-slot:[`item.low`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`indi${item.token}low`">{{ item.low ? item.low : "0.00" }}</span>
        </template>
        <template v-slot:no-data>
          <v-col cols="12" class="text-center pa-16">
            <div class="mx-auto">
              <img class="align-self-stretch mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" alt="no data" />
              <h5 class="txt-999 font-weight-regular">There is no Indices data here yet!</h5>
            </div>
          </v-col>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import {getIndexList, getLtpdata} from "@/components/mixins/getAPIdata";
import eventBus from "../../../eventBus.js";

export default {
  data: () => ({
    bcitems: [
      {
        text: "Stocks",
        disabled: false,
        href: "/stocks",
      },
      {
        text: "All indices",
        disabled: true,
        href: "/stocks/allindices",
      },
      {
        text: "exch",
        disabled: true,
        href: "/stocks/allindices?exch=NSE",
      },
    ],
    uid: null,
    mtoken: null,
    stoken: null,

    isloading: true,
    exchtype: "NSE",

    opensearch: null,

    totalcount: 0,
    exchcount: {},
    allindicedata: [],
    tablesecdata: [],
  }),

  computed: {
    indiceheader() {
      return [
        {text: "Index name", value: "tsym", sortable: false, class: "ws-p"},
        {text: "Price", value: "ltp", sortable: false, align: "right", class: "ws-p"},
        // { text: 'OI', value: 'oi', sortable: false, align: 'right', class: 'ws-p' },
        {text: "Open", value: "op", align: "right", class: "ws-p"},
        {text: "High", value: "high", align: "right", class: "ws-p"},
        {text: "Low", value: "low", align: "right", class: "ws-p"},
        {text: "Close", value: "cp", align: "right", class: "ws-p"},
        {text: "Volume", value: "vol", align: "right", class: "ws-p"},
        {text: "", value: "exch", filter: this.setExchpos, align: " d-none"},
      ];
    },
  },

  mounted() {
    eventBus.$emit("tabBar-load");
    eventBus.$emit("login-event");
    eventBus.$on("user-event", () => {
      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        if (!this.uid && !this.stoken) {
          this.mtoken = sessionStorage.getItem("msession");
          this.stoken = sessionStorage.getItem("usession");
          this.uid = sessionStorage.getItem("userid");
        }
      }
      this.getAllindicedata();
    });

    eventBus.$on("web-scoketConn", (data, page) => {
      if (page == "stockINC" && this.allindicedata && this.allindicedata.length > 0) {
        this.optionChainDataParse(data);
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("user-event");
    // eventBus.$off('web-scoketConn');
  },
  methods: {
    setTabchange() {
      var t = this.tablesecdata;
      this.tablesecdata = [];
      setTimeout(() => {
        this.tablesecdata = t;
      }, 1);
    },
    setSinglestock(tsym, item) {
      if (this.uid) {
        let path = [0, item.token, item.exch, item.tsym];
        this.$router.push({name: "stocks details", params: {val: path}});
      } else {
        this.$router.push({name: "stocks advance decline", params: {abc: tsym, main: "find"}});
        // this.$router.push(`/stocks/${tsym.toLowerCase()}`);
      }
    },
    async getAllindicedata() {
      let data = await getIndexList();
      if (data && Object.keys(data).length > 0) {
        var wsdata = [];
        Object.entries(data).forEach(([key, value], i) => {
          if (i < 3) {
            for (let d = 0; d < value.length; d++) {
              if (value[d] && value[d].idxname) {
                wsdata.push({
                  exch: key,
                  token: value[d].token,
                  tsym: value[d].idxname,
                });
              }
              this.allindicedata.push({
                tsym: value[d].idxname,
                token: value[d].token,
                pe: 0,
                pb: 0,
                exch: key,
              });
            }
          }
        });
        this.setWebsocket("sub", wsdata, "allind");
        this.totalcount = this.allindicedata.length;
        this.tablesecdata = this.allindicedata;
        // this.allindicedata = data;

        this.exchcount = this.tablesecdata.reduce((acc, {exch}) => {
          acc[exch] = (acc[exch] || 0) + 1;
          return acc;
        }, {});
        console.log(this.exchcount);
      } else {
        this.totalcount = 0;
        this.allindicedata = [];
      }
      this.isloading = false;
    },
    async setWebsocket(flow, data, is) {
      if (this.uid) {
        eventBus.$emit("web-scoketOn", flow, data, is, "stockINC");
      } else {
        let raw = await getLtpdata(data);
        raw = raw.data;
        for (let l = 0; l < this.allindicedata.length; l++) {
          let v = raw[this.allindicedata[l].token];
          if (v) {
            this.allindicedata[l]["ltp"] = Number(v.lp).toFixed(2);
            this.allindicedata[l]["ch"] = Number(this.allindicedata[l].ltp - Number(v.close)).toFixed(2);
            this.allindicedata[l]["chp"] = Number(v.change);
            this.allindicedata[l]["vol"] = v.vol ? Number(v.vol).toFixed(2) : (0).toFixed(2);
            this.allindicedata[l]["op"] = Number(v.open) ? Number(v.open).toFixed(2) : (0).toFixed(2);
            this.allindicedata[l]["cp"] = Number(v.close) ? Number(v.close).toFixed(2) : (0).toFixed(2);
            this.allindicedata[l]["high"] = Number(v.high) ? Number(v.high).toFixed(2) : (0).toFixed(2);
            this.allindicedata[l]["low"] = Number(v.low) ? Number(v.low).toFixed(2) : (0).toFixed(2);
            // this.$set(this.allindicedata, l, this.allindicedata[l]);
          }
        }
      }
    },
    setExchpos(value) {
      if (this.exchtype == "NSE") {
        if (!this.exchtype) {
          return true;
        }
        return value === "NSE";
      } else if (this.exchtype == "BSE") {
        if (!this.exchtype) {
          return true;
        }
        return value === "BSE";
      } else {
        if (!this.exchtype) {
          return true;
        }
        return value === this.exchtype;
      }
    },
    optionChainDataParse(data) {
      let p = this.allindicedata.findIndex((o) => o.token == data.token);
      if (p >= 0 && this.allindicedata[p].token == data.token) {
        this.allindicedata[p].ltp = Number(data.lp).toFixed(2);
        this.allindicedata[p]["ch"] = Number(data.ch) > 0 || Number(data.ch) < 0 ? Number(data.ch).toFixed(2) : (0).toFixed(2);
        this.allindicedata[p]["chp"] = Number(data.chp).toFixed(2);
        this.allindicedata[p]["vol"] = Number(data.volume).toFixed(2);
        this.allindicedata[p]["op"] = Number(data.open_price) ? Number(data.open_price).toFixed(2) : (0).toFixed(2);
        this.allindicedata[p]["cp"] = Number(data.prev_close_price) ? Number(data.prev_close_price).toFixed(2) : (0).toFixed(2);
        this.allindicedata[p]["high"] = Number(data.high_price) ? Number(data.high_price).toFixed(2) : (0).toFixed(2);
        this.allindicedata[p]["low"] = Number(data.low_price) ? Number(data.low_price).toFixed(2) : (0).toFixed(2);

        let tag = document.getElementById(`indi${data.token}ltp`);
        if (tag) {
          document.getElementById(`indi${data.token}ltp`).innerHTML = this.allindicedata[p].ltp;
          document.getElementById(`indi${data.token}ch`).innerHTML = this.allindicedata[p].ch;
          document.getElementById(`indi${data.token}chp`).innerHTML = ` (${this.allindicedata[p].chp}%)`;
          eventBus.$emit("color-event", `indi${data.token}chpclr`, this.allindicedata[p].ch > 0 ? "maingreen--text" : this.allindicedata[p].ch < 0 ? "mainred--text" : "subtext--text");
          document.getElementById(`indi${data.token}vol`).innerHTML = this.allindicedata[p].vol;
          document.getElementById(`indi${data.token}op`).innerHTML = this.allindicedata[p].op;
          document.getElementById(`indi${data.token}cp`).innerHTML = this.allindicedata[p].cp;
          document.getElementById(`indi${data.token}high`).innerHTML = this.allindicedata[p].high;
          document.getElementById(`indi${data.token}low`).innerHTML = this.allindicedata[p].low;
        }
        // this.$set(this.allindicedata, p, this.allindicedata[p]);
      }
    },
  },
};
</script>
