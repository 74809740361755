<template>
  <div>
    <v-breadcrumbs class="pa-0 pt-2" :items="bcitems">
      <template v-slot:item="{item}">
        <v-breadcrumbs-item :class="!item.disabled ? 'primary--text cursor-p' : ''" @click="$router.push(item.href)" :disabled="item.disabled">
          {{ item.text == "this" ? (tradeaction == 3 ? "Most active" : tradeaction == 0 ? "Top gainer" : tradeaction == 1 ? "Top losers" : tradeaction == 2 ? "Volume breakout" : "") : item.text }}
        </v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card style="border: thin solid var(--outline) !important" outlined class="rounded-lg mt-4 mb-8 crd-trn">
      <v-toolbar class="elevation-0 mb-4 mb-sm-2 my-4" dense color="transparent">
        <img width="40px" :src="require(`@/assets/stocks/${tradeaction == 0 ? 'tg' : tradeaction == 1 ? 'tl' : tradeaction == 2 ? 'vb' : 'ma'}.svg`)" :alt="tradeaction == 0 ? 'tg' : tradeaction == 1 ? 'tl' : tradeaction == 2 ? 'vb' : 'ma'" class="mr-2" />
        <p class="font-weight-bold title mb-0 text-none">{{ tradeaction == 3 ? "Most active" : tradeaction == 0 ? "Top gainer" : tradeaction == 1 ? "Top losers" : tradeaction == 2 ? "Volume breakout" : "" }}</p>
        <v-spacer></v-spacer>

        <v-select
          @change="getToplistdata('yes')"
          :readonly="isloading"
          style="max-width: 140px"
          v-model="trader1"
          hide-details
          append-icon="mdi-chevron-down"
          item-text="text"
          item-value="value"
          class="rounded-pill mr-3 d-none d-sm-flex"
          dense
          flat
          solo
          background-color="secbg"
          :items="trader1item"
          label="Condition"
        ></v-select>

        <v-select
          @change="setTabchange()"
          :readonly="isloading"
          style="max-width: 120px"
          v-model="tradeaction"
          hide-details
          append-icon="mdi-chevron-down"
          item-text="t"
          item-value="k"
          class="rounded-pill d-sm-none"
          dense
          flat
          solo
          background-color="secbg"
          :items="[
            {k: 0, t: 'Top gainer'},
            {k: 1, t: 'Top losers'},
            {k: 2, t: 'Volume breakout'},
            {k: 3, t: 'Most active'},
          ]"
        ></v-select>
        <v-text-field style="max-width: 220px" :disabled="isloading" v-model="opensearch" hide-details prepend-inner-icon="mdi-magnify" label="Search" class="rounded-pill d-none d-sm-flex" dense flat solo background-color="secbg"></v-text-field>
      </v-toolbar>
      <v-tabs class="d-none d-sm-flex" fixed color="maintext--text" v-model="tradeaction" @change="setTabchange()">
        <v-tab class="font-weight-bold subtitle-2 mb-0 text-none">Top gainer</v-tab>
        <v-tab class="font-weight-bold subtitle-2 mb-0 text-none">Top losers</v-tab>
        <v-tab class="font-weight-bold subtitle-2 mb-0 text-none">Volume breakout</v-tab>
        <v-tab class="font-weight-bold subtitle-2 mb-0 text-none">Most active</v-tab>
        <v-tabs-slider color="maintext--text"></v-tabs-slider>
      </v-tabs>
      <v-divider></v-divider>
      <v-data-table must-sort :sort-by="[tradeaction == 2 ? 'vol' : 'chp']" :sort-desc="[true]" mobile-breakpoint fixed-header :loading="isloading" class="rounded-lg overflow-y-auto" :headers="tradeheader" :search="opensearch" :items="traditems" :items-per-page="10">
        <template v-slot:[`item.tsym`]="{item}">
          <span @click="setSinglestock(item.tsym.split('-')[0], item)" class="font-weight-medium text-capitalize txt-dec-cust ws-p">{{ item.tsym }}</span>
        </template>

        <template v-slot:[`item.ltp`]="{item}">
          <p class="mb-0 lh-18">
            <span class="font-weight-medium maintext--text">₹<span :id="`stkmrk${item.token}ltp`" v-text="item.ltp ? Number(item.ltp).toFixed(2) : '0.00'"></span></span> <br />
            <span class="font-weight-medium fs-12 ws-p" :id="`stkmrk${item.token}chpclr`" :class="item.ch > 0 ? 'maingreen--text' : item.ch < 0 ? 'mainred--text' : 'subtext--text'">
              <span :id="`stkmrk${item.token}ch`" v-text="item.ch ? item.ch : '0.00'"> </span>
              <span :id="`stkmrk${item.token}chp`" v-text="` (${item.chp ? item.chp : '0.00'}%)`"></span
            ></span>
          </p>
        </template>

        <template v-slot:[`item.vol`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`stkmrk${item.token}vol`">{{ item.vol ? item.vol : "0.00" }}</span>
        </template>
        <template v-slot:[`item.op`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`stkmrk${item.token}op`">{{ item.op ? item.op : "0.00" }}</span>
        </template>
        <template v-slot:[`item.cp`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`stkmrk${item.token}cp`">{{ item.cp ? item.cp : "0.00" }}</span>
        </template>
        <template v-slot:[`item.high`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`stkmrk${item.token}high`">{{ item.high ? item.high : "0.00" }}</span>
        </template>
        <template v-slot:[`item.low`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`stkmrk${item.token}low`">{{ item.low ? item.low : "0.00" }}</span>
        </template>
        <template v-slot:no-data>
          <v-col cols="12" class="text-center pa-16">
            <div class="mx-auto">
              <img class="align-self-stretch mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" alt="no data" />
              <h5 class="txt-999 font-weight-regular">There is no data here yet!</h5>
            </div>
          </v-col>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {getTopList, getLtpdata} from "@/components/mixins/getAPIdata";
import eventBus from "../../../eventBus.js";

export default {
  /* eslint-disable */
  data: () => ({
    bcitems: [
      {
        text: "Stocks",
        disabled: false,
        href: "/stocks",
      },
      {
        text: "Markets",
        disabled: true,
        href: "/stocks/market",
      },
      {
        text: "this",
        disabled: true,
      },
    ],
    uid: null,
    mtoken: null,
    stoken: null,

    trader1item: [
      {text: "NSE", value: "NSEALL"},
      {text: "NFO", value: "NFOALL"},
    ],
    trader1: "NSEALL",

    tradeaction: 0,
    traditems: [],
    tradeactionitem: [],
    isloading: true,
    opensearch: null,

    wsdata: [],
  }),
  computed: {
    tradeheader() {
      return [
        {text: "Symbol", value: "tsym", sortable: false, class: "ws-p"},
        {text: "Price", value: "ltp", sortable: false, align: "right", class: "ws-p"},
        {text: "Open", value: "op", align: "right", class: "ws-p"},
        {text: "High", value: "high", align: "right", class: "ws-p"},
        {text: "Low", value: "low", align: "right", class: "ws-p"},
        {text: "Close", value: "cp", align: "right", class: "ws-p"},
        {text: "Volume", value: "vol", align: "right", class: "ws-p"},
      ];
    },
  },
  created() {
    let params = this.$route.params;
    if (params.abc >= 0) {
      this.tradeaction = params.abc;
    } else {
      this.tradeaction = 0;
    }
  },
  mounted() {
    eventBus.$emit("tabBar-load");
    eventBus.$emit("login-event");
    eventBus.$on("user-event", () => {
      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        if (!this.uid && !this.stoken) {
          this.mtoken = sessionStorage.getItem("msession");
          this.stoken = sessionStorage.getItem("usession");
          this.uid = sessionStorage.getItem("userid");
        }
      }
      this.getToplistdata();
    });

    eventBus.$on("web-scoketConn", (data, page) => {
      if (page == "stockMKT" && this.tradeactionitem && this.tradeactionitem.length == 4) {
        this.optionChainDataParse(data);
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("user-event");
    // eventBus.$off('web-scoketConn');
  },
  methods: {
    setTabchange() {
      this.traditems = [];
      setTimeout(() => {
        this.traditems = this.tradeactionitem[this.tradeaction];
      }, 1);
    },
    setSinglestock(tsym, item) {
      if (this.uid) {
        let path = [0, item.token, item.exch, item.tsym];
        this.$router.push({name: "stocks details", params: {val: path}});
      } else {
        this.$router.push(`stocks/${tsym.toLowerCase()}`);
      }
    },
    async getToplistdata(change) {
      if (change == "yes") {
        this.setWebsocket("unsub-D", this.wsdata, "mkt");
      }
      this.tradeactionitem = [];
      this.traditems = [];
      this.isloading = true;
      let lsto = await getTopList([this.trader1 == "NSEALL" ? "NSE" : "NFO", this.trader1, "mostActive"]);
      let lstt = await getTopList([this.trader1 == "NSEALL" ? "NSE" : "NFO", this.trader1, "topG_L"]);

      if (lsto.stat == "Ok" && lstt.stat == "Ok") {
        this.tradeactionitem.push(lstt.topGainers);
        this.tradeactionitem.push(lstt.topLosers);
        this.tradeactionitem.push(lsto.byVolume);
        this.tradeactionitem.push(lsto.byValue);

        let arr = this.tradeactionitem[0].concat(this.tradeactionitem[1].concat(this.tradeactionitem[2].concat(this.tradeactionitem[3])));
        // let wsdata = [];
        // arr.map((o) => wsdata.push({ exch: o.exch, token: o.token, tsym: o.tsym, }))
        // console.log("arr", wsdata, this.tradeactionitem);
        this.setWebsocket("sub", arr, "mkt");
        this.wsdata = arr;
        this.traditems = this.tradeactionitem[this.tradeaction];
      }
      this.isloading = false;
    },
    async setWebsocket(flow, data, is) {
      if (this.uid) {
        eventBus.$emit("web-scoketOn", flow, data, is, "stockMKT");
      } else {
        let raw = await getLtpdata(data);
        raw = raw.data;
        if (raw) {
          for (let x = 0; x < this.tradeactionitem.length; x++) {
            for (let x_is = 0; x_is < this.tradeactionitem[x].length; x_is++) {
              let data = raw[this.tradeactionitem[x][x_is].token];
              if (data) {
                this.tradeactionitem[x][x_is].ltp = Number(data.lp) ? Number(data.lp).toFixed(2) : (0).toFixed(2);
                this.tradeactionitem[x][x_is]["ch"] = Number(data.lp) && Number(data.close) ? (Number(data.lp) - Number(data.close)).toFixed(2) : (0).toFixed(2);
                this.tradeactionitem[x][x_is]["chp"] = Number(data.change).toFixed(2);
                this.tradeactionitem[x][x_is]["vol"] = Number(data.vol).toFixed(2);
                this.tradeactionitem[x][x_is]["op"] = Number(data.open) ? Number(data.open).toFixed(2) : (0).toFixed(2);
                this.tradeactionitem[x][x_is]["cp"] = Number(data.close) ? Number(data.close).toFixed(2) : (0).toFixed(2);
                this.tradeactionitem[x][x_is]["high"] = Number(data.high) ? Number(data.high).toFixed(2) : (0).toFixed(2);
                this.tradeactionitem[x][x_is]["low"] = Number(data.low) ? Number(data.low).toFixed(2) : (0).toFixed(2);
                // this.$set(this.tradeactionitem[x], x_is, this.tradeactionitem[x][x_is]);
              }
            }
          }
        }
      }
    },
    optionChainDataParse(data) {
      let o = this.tradeactionitem[0].findIndex((o) => o.token == data.token);
      let t = this.tradeactionitem[1].findIndex((o) => o.token == data.token);
      let r = this.tradeactionitem[2].findIndex((o) => o.token == data.token);
      let f = this.tradeactionitem[3].findIndex((o) => o.token == data.token);

      if (o >= 0 || t >= 0 || r >= 0 || f >= 0) {
        for (let x = 0; x < this.tradeactionitem.length; x++) {
          let x_is = x == 0 && o >= 0 ? o : x == 1 && t >= 0 ? t : x == 2 && r >= 0 ? r : x == 3 && f >= 0 ? f : null;
          if (x_is >= 0 && this.tradeactionitem[x] && this.tradeactionitem[x][x_is] && this.tradeactionitem[x][x_is].token == data.token) {
            this.tradeactionitem[x][x_is].ltp = Number(data.lp).toFixed(2);
            this.tradeactionitem[x][x_is]["ch"] = Number(data.ch) > 0 || Number(data.ch) < 0 ? Number(data.ch).toFixed(2) : (0).toFixed(2);
            this.tradeactionitem[x][x_is]["chp"] = Number(data.chp).toFixed(2);
            this.tradeactionitem[x][x_is]["vol"] = Number(data.volume);
            this.tradeactionitem[x][x_is]["op"] = Number(data.open_price) ? Number(data.open_price).toFixed(2) : (0).toFixed(2);
            this.tradeactionitem[x][x_is]["cp"] = Number(data.prev_close_price) ? Number(data.prev_close_price).toFixed(2) : (0).toFixed(2);
            this.tradeactionitem[x][x_is]["high"] = Number(data.high_price) ? Number(data.high_price).toFixed(2) : (0).toFixed(2);
            this.tradeactionitem[x][x_is]["low"] = Number(data.low_price) ? Number(data.low_price).toFixed(2) : (0).toFixed(2);

            let tag = document.getElementById(`stkmrk${data.token}ltp`);
            if (tag) {
              document.getElementById(`stkmrk${data.token}ltp`).innerHTML = this.tradeactionitem[x][x_is].ltp;
              document.getElementById(`stkmrk${data.token}ch`).innerHTML = this.tradeactionitem[x][x_is].ch;
              document.getElementById(`stkmrk${data.token}chp`).innerHTML = ` (${this.tradeactionitem[x][x_is].chp}%)`;
              eventBus.$emit("color-event", `stkmrk${data.token}chpclr`, this.tradeactionitem[x][x_is].ch > 0 ? "maingreen--text" : this.tradeactionitem[x][x_is].ch < 0 ? "mainred--text" : "subtext--text");
              document.getElementById(`stkmrk${data.token}vol`).innerHTML = this.tradeactionitem[x][x_is].vol;
              document.getElementById(`stkmrk${data.token}op`).innerHTML = this.tradeactionitem[x][x_is].op;
              document.getElementById(`stkmrk${data.token}cp`).innerHTML = this.tradeactionitem[x][x_is].cp;
              document.getElementById(`stkmrk${data.token}high`).innerHTML = this.tradeactionitem[x][x_is].high;
              document.getElementById(`stkmrk${data.token}low`).innerHTML = this.tradeactionitem[x][x_is].low;
            }
            // this.$set(this.tradeactionitem[x], x_is, this.tradeactionitem[x][x_is]);
          }
        }
      }
    },
  },
};
</script>
