<template>
    <div>
        <div style="position: sticky !important; top: 68px; z-index: 1;">
            <v-toolbar class="tool-sty elevation-0 pl-4" height="40px" color="cardbg" dense>
                <v-tabs fixed color="maintext" v-model="bodytab" show-arrows dense>
                    <template v-for="(t, index) in dashitems">
                        <v-tab :key="index" v-if="t.tab" class="text-none text-start min-w-fit">
                            <span class="text-center tab-txt font-weight-medium">{{
                                t.txt
                                }}</span></v-tab>
                    </template>
                    <v-tabs-slider class="" color="maintext"></v-tabs-slider>
                </v-tabs>
                <v-spacer></v-spacer>
                <v-icon class="ml-3 cursor-p" @click="getAllalerts()" color="maintext" size="24">mdi-reload</v-icon>

            </v-toolbar>
            <v-divider></v-divider>
            <v-progress-linear v-if="loader" color="primary" indeterminate rounded></v-progress-linear>
        </div>
        <v-tabs-items v-model="bodytab">
            <v-tab-item>
                <div v-if="allalert.b && allalert.b.length > 0">
                    <v-card v-for="(n, e) in allalert.b" :key="e" class="crd-trn rounded-lg elevation-0 mb-0">
                        <v-list-item two-line class="px-2 pr-sm-3">
                            <v-list-item-content class="py-0">
                                <p class="fs-14 mb-2 text-rap-l2 lh-20" v-html="n.dmsg"></p>
                                <v-list-item-subtitle class="fs-12 font-weight-regular">{{
                                    n.norentm
                                }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                    </v-card>
                </div>
                <div v-else class="mx-auto text-center py-16">
                    <img class="align-self-stretch mx-auto" width="80px" :src="require('@/assets/no data folder.svg')"
                        alt="no data" />
                    <h5 class="txt-999 font-weight-regular">There is no
                        alert here yet!</h5>
                </div>
            </v-tab-item>
            <v-tab-item>
                <v-data-table :loading="loader" must-sort :sort-by="['norentm']" :sort-desc="[true]" hide-default-footer
                    fixed-header class="mt-3 rounded-lg overflow-y-auto"
                    style="border-radius: 4px; border: 1px solid var(--outline);" height="480px" :headers="orderheader"
                    :search="opensearch" :items="allalert.p" :items-per-page="-1">
                    <template v-slot:body="{ items, headers }">
                        <tbody v-if="allalert.p && allalert.p.length > 0">
                            <tr class="table-row" v-for="(item, o) in items" :key="o">
                                <td class="pos-rlt">
                                    <p class="font-weight-medium maintext--text mb-0 table-hov-text ws-p mr-4">{{ item.tsym
                                        ? item.tsym : '' }}
                                        <span class="ml-1 txt-999 fs-10">{{ item.exch ? item.exch : '' }}</span>
                                    </p>
                                    <div @click.stop class="pos-abs table-hov" style="top:15px; right:0;">
                                        <v-btn
                                            @click="switchbus.$emit('menudialog', 'order', item.token, item.exch, item.tsym, 'b')"
                                            min-width="20px" color="maingreen"
                                            class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                                            B
                                        </v-btn>
                                        <v-btn
                                            @click="switchbus.$emit('menudialog', 'order', item.token, item.exch, item.tsym, 's')"
                                            min-width="20px" color="mainred"
                                            class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                                            S
                                        </v-btn>
                                        <v-btn @click="setSSDtab('chart', item.token, item.exch, item.tsym)"
                                            style="border: 1px solid var(--outline);" min-width="20px" color="mainbg"
                                            class="px-0 font-weight-bold elevation-0 mr-1" x-small>
                                            <v-icon size="18" color="maintext">mdi-chart-line-variant</v-icon>
                                        </v-btn>
                                        <v-tooltip top color="black">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div v-bind="attrs" v-on="on">
                                                    <v-btn
                                                        @click="setSSDtab('c-alert', item.token, item.exch, item.tsym, '', item)"
                                                        style="border: 1px solid var(--outline);" min-width="20px" color="mainbg"
                                                        class="px-0 font-weight-bold elevation-0 mr-1"
                                                        x-small>
                                                        <v-icon size="18" color="maintext">mdi-close</v-icon>
                                                    </v-btn>
                                                </div>
                                            </template>
                                            <span>Cancel</span>
                                        </v-tooltip>
                                        <v-tooltip top color="black">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div v-bind="attrs" v-on="on">
                                                    <v-btn
                                                        @click="switchbus.$emit('menudialog', 'm-alert', item.token, item.exch, item.tsym, '', item)"
                                                        style="border: 1px solid var(--outline);" min-width="20px" color="mainbg"
                                                        class="px-0 font-weight-bold elevation-0 mr-1"
                                                        x-small>
                                                        <v-icon size="16" color="maintext">mdi-pen</v-icon>
                                                    </v-btn>
                                                </div>
                                            </template>
                                            <span>Modify</span>
                                        </v-tooltip>
                                        <v-menu close-on-click absolute offset-y class="table-menu">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs" v-on="on" style="border: 1px solid var(--outline);"
                                                    min-width="20px" color="mainbg"
                                                    class="px-0 font-weight-bold elevation-0 mr-1" x-small>
                                                    <v-icon size="20" color="maintext">mdi-dots-horizontal</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-card class="table-menu-list">
                                                <v-list dense>
                                                    <div v-for="(m, k) in menulist" :key="k">
                                                        <v-list-item
                                                            @click="m.type != '' ? setSSDtab(m.type, item.token, item.exch, item.tsym, '', item) : setHoldingrowdata(item)"
                                                            class="pl-3 pr-6">
                                                            <v-list-item-icon class="mr-3 text-center">
                                                                <img v-if="m.icon > 2" width="20px" class="pl-1"
                                                                    :src="require(`@/assets/orderbook/${m.icon}.svg`)" />
                                                                <v-icon v-else color="#506D84">{{ m.icon }}</v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title
                                                                    class="subline--text font-weight-medium fs-14">{{ m.name
                                                                    }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-divider v-if="m.hr" class="mx-3"></v-divider>
                                                    </div>
                                                </v-list>
                                            </v-card>
                                        </v-menu>
                                    </div>
                                </td>
                                <td>
                                    <p class="font-weight-medium maintext--text mb-0">{{ item.ai_t ?
                                        item.ai_t.split('_')[0] : '' }}</p>
                                </td>
                                <td>
                                    <p class="font-weight-medium maintext--text mb-0">{{ item.ai_t.includes('_A') ? ">" :
                                        item.ai_t.includes('_B') ? "<" : "Select" }}</p>
                                </td>

                                <td class="text-right">
                                    <p class="font-weight-medium maintext--text mb-0">{{ item.d ? item.d : '' }}</p>
                                </td>
                                <td class="text-right">
                                    <p class="font-weight-medium maintext--text mb-0">{{ item.al_id ? item.al_id : '' }}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <td :colspan="headers.length" class="text-center">
                                <div class="mx-auto py-16 mt-16">
                                    <img class="mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" />
                                    <h4 class="txt-999 font-weight-regular caption">There is no pending
                                        alert <br>
                                        here
                                        yet!</h4>
                                </div>
                            </td>
                        </tbody>
                    </template>
                </v-data-table>
            </v-tab-item>
            <v-tab-item>
                <div v-if="allalert.m && allalert.m.length > 0">
                    <v-card v-for="(n, e) in allalert.m" :key="e" class="crd-trn rounded-lg elevation-0 mb-0">
                        <v-list-item two-line class="px-2 pr-sm-3">
                            <v-list-item-content class="py-0">
                                <p class="fs-14 mb-2 text-rap-l2 lh-20"
                                    v-html="`${n.exch}: ${n.exch_msg}`">
                                </p>
                                <v-list-item-subtitle class="fs-12 font-weight-regular">{{
                                    n.exch_tm
                                    }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                    </v-card>
                </div>
                <div v-else class="mx-auto text-center py-16">
                    <img class="align-self-stretch mx-auto" width="80px" :src="require('@/assets/no data folder.svg')"
                        alt="no data" />
                    <h5 class="txt-999 font-weight-regular">There is no exch
                        alert here yet!</h5>
                </div>
            </v-tab-item>
            <v-tab-item>
                <div v-if="allalert.s && allalert.s.length > 0">
                    <v-card v-for="(n, e) in allalert.s" :key="e" class="crd-trn rounded-lg elevation-0 mb-0">
                        <v-list-item two-line class="px-2 pr-sm-3">
                            <v-list-item-content class="pb-3 pt-2">
                                <p class="fs-14 mb-1 mt-1 text-rap-l2 lh-20">{{ e + 1 }}. {{ n.exch
                                    }}
                                </p>
                                <p class="font-weight-regular fs-13 mb-0 text-rap-l2 lh-20"><span
                                        class="subtext--text">Status: </span>
                                    <v-icon size="10"
                                        :color="n.exchstat.includes('OPEN') ? '#1FD601' : '#D60101'">mdi-checkbox-blank-circle</v-icon>
                                    {{ n.exchstat }} | <span class="subtext--text">Market type: </span> {{ n.exchtype }}
                                </p>

                                <v-list-item-subtitle class="fs-12 font-weight-regular mb-0">{{
                                    n.description
                                    }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                    </v-card>
                </div>
                <div v-else class="mx-auto text-center py-16">
                    <img class="align-self-stretch mx-auto" width="80px" :src="require('@/assets/no data folder.svg')"
                        alt="no data" />
                    <h5 class="txt-999 font-weight-regular">There is no
                        alert here yet!</h5>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog v-model="canceldialog" max-width="400">
            <v-card class="rounded-xl elevation-0 text-center pt-8 pb-6 overflow-hidden">
                <img src="@/assets/orderbook/cancel-icon.svg" alt="cancel icon">
                <p class="font-weight-medium mt-3 fs-22 lh-24 mb-8">Are you sure you want to <br> cancel <b>{{
                    singledata.tsym
                        }}
                    </b> alert?</p>
                <v-row class="px-6" no-gluttters>
                    <v-col cols="6">
                        <v-btn @click="canceldialog = false, singledata = []" color="outline"
                            class="rounded-pill text-none subtext--text font-weight-bold elevation-0" block
                            height="40px">No</v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn @click="setCancelalert()" color="btnclr"
                            class="rounded-pill text-none btntext--text font-weight-bold elevation-0" block
                            height="40px">Yes</v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import StockOrderSrc from '../Orders/StockOrderSrc.vue';
// import MutualOrderbook from '../Dashboard/mutualfund/MutualOrderbook.vue';
// import IpoOrderbook from '../Dashboard/ipos/IpoOrderbook.vue';
// import BondsOrderbook from '../Dashboard/bonds/BondsOrderbook.vue';

import eventBus from '../../eventBus.js';
import { setMalert, getAlertAPi } from "../../components/mixins/getAPIdata.js";

export default {
    data: () => ({
        filter: 0,
        bodytab: 0,
        loader: true,
        opensearch: null,
        canceldialog: false,
        dashitems: [
            { tab: true, txt: 'Alert' },
            { tab: true, txt: 'Pending alert' },
            { tab: true, txt: 'Exch alert' },
            { tab: true, txt: 'Exch status' },
        ],

        dashoptions: [
            { tab: true, key: 0, txt: 'Orders Book' },
            { tab: true, key: 1, txt: 'Trade Book' },
            { tab: true, key: 2, txt: 'GTT Order' },
            { tab: true, key: 3, txt: 'Basket Order' },
            { tab: true, key: 4, txt: 'SIP Order' },
            // { tab: true, key: 5, txt: 'XSIP' },
        ],

        menulist: [
            { name: 'Modify Alert', icon: 11, type: 'm-alert' },
            { name: 'Exit/Cancel Alert', icon: 12, type: 'c-alert', hr: true },

            { name: 'Create GTT / GTC', icon: 4, type: 'cGTT' },
            { name: 'Create Alert', icon: 5, type: 'alert' },
            { name: 'Market Depth', icon: 6, type: 'depth' },
            { name: 'Chart', icon: 7, type: 'chart', hr: true },
            { name: 'Fundamentals', icon: 9, type: 'Funda' },
            { name: 'Details', icon: 10, type: '' }
        ],

        uid: null,
        stoken: null,
        mtoken: null,

        switchbus: null,
        singledata: [],

        allalert: [{ 'b': [] }, { 'p': [] }, { 'm': [] }, { 's': [] }],
    }),
    created() {
        this.switchbus = eventBus;
    },
    computed: {
        orderheader() {
            return [
                { text: 'Instrument', value: 'tsym', sortable: false },
                { text: 'Alert type', value: 'ai_t', sortable: false },
                { text: 'Condition', value: 'ai_t', sortable: false },
                { text: 'Value', value: 'd', sortable: false, align: 'right' },
                { text: 'Order no', value: 'al_id', sortable: false, align: 'right', class: 'ws-p' },
            ]
        }
    },
    mounted() {
        this.$emit('receive', 'stk');
        eventBus.$emit('login-event');

        eventBus.$on('user-event', () => {
            let res = sessionStorage.getItem("c3RhdHVz");
            if (res == "dmFsaWR1c2Vy") {
                this.mtoken = sessionStorage.getItem("msession");
                this.stoken = sessionStorage.getItem("usession");
                this.uid = sessionStorage.getItem("userid");
                this.getAllalerts();
            } else {
                this.$router.push('/')
            }
        })
        eventBus.$on('orderbook-update', (book) => {
            if (book == 'orders') {
                this.getAllalerts();
            }
        })
    },
    beforeDestroy() {
        eventBus.$off('orderbook-update');
        eventBus.$off('user-event');
    },

    methods: {
        async getAllalerts() {
            this.loader = true;
            let alert = [{ 'b': [] }, { 'p': [] }, { 'm': [] }, { 's': [] }]
            alert.b = await getAlertAPi('GetBrokerMsg')
            alert.p = await getAlertAPi('GetPendingAlert')
            alert.m = await getAlertAPi('ExchMsg')
            alert.s = await getAlertAPi('ExchStatus')
            if (alert.b && alert.b.length > 0) {
                for (let l = 0; l < alert.b.length; l++) {
                    alert.b[l]['dmsg'] = alert.b[l].dmsg && alert.b[l].dmsg.includes('href=') ? alert.b[l].dmsg.replace("href=", "target=_blank href=") : alert.b[l].dmsg
                }
                this.allalert['b'] = alert.b;
            } else {
                this.allalert['b'] = []
                eventBus.$emit('snack-event', 2, alert.b.emsg ? alert.b.emsg : alert.b)
            }
            if (alert.p && alert.p.length > 0) {
                this.allalert['p'] = alert.p;
            } else {
                this.allalert['p'] = []
                eventBus.$emit('snack-event', 2, alert.p.emsg ? alert.p.emsg : alert.p)
            }
            if (alert.m && alert.m.length > 0) {
                this.allalert['m'] = alert.m;
            } else {
                this.allalert['m'] = []
                eventBus.$emit('snack-event', 2, alert.m.emsg ? alert.m.emsg : alert.m)
            }
            if (alert.s && alert.s.length > 0) {
                this.allalert['s'] = alert.s;
            } else {
                this.allalert['s'] = []
                eventBus.$emit('snack-event', 2, alert.s.emsg ? alert.s.emsg : alert.s)
            }
            this.loader = false;
        },
        setSSDtab(type, token, exch, tsym, trantype, item) {
            if (type == 'c-alert') {
                this.singledata = item;
                this.canceldialog = true;
            } else if (type == 'alert' || type == 'm-alert') {
                eventBus.$emit('menudialog', type, token, exch, tsym, trantype, item)
            } else if (type == 'cGTT') {
                eventBus.$emit('menudialog', 'order-GTT', token, exch, tsym, 'b');
            } else {
                let path = [type, token, exch, tsym]
                this.$router.push({ name: 'stocks details', params: { val: path } })
            }
        },
        async setCancelalert() {
            this.loader = true;
            let data = { "uid": `${this.uid}`, "al_id": `${this.singledata.al_id}` };
            let alert = await setMalert(data, 'cancel');
            if (alert.stat == "OI deleted") {
                eventBus.$emit('snack-event', 1, `Alert has been Cancelled for ${this.singledata.tsym}`)
                this.allalert.p = [];
                this.getAllalerts();
            } else {
                eventBus.$emit('snack-event', 2, alert.emsg ? alert.emsg : alert)
            }
            this.loader = false;
            this.singledata = [];
            this.canceldialog = false;
        },

    },
    // components: { StockOrderSrc, MutualOrderbook, IpoOrderbook, BondsOrderbook }
}
</script>