<template>
  <div>
    <v-breadcrumbs class="pa-0 pt-2" :items="bcitems">
      <template v-slot:item="{item}">
        <v-breadcrumbs-item :class="!item.disabled ? 'primary--text cursor-p' : ''" @click="$router.push(item.href)" :disabled="item.disabled">
          {{ item.text == "this" ? screent0 : item.text }}
        </v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card style="border: thin solid var(--outline) !important" outlined class="rounded-lg mt-4 mb-8 crd-trn">
      <v-toolbar class="elevation-0 my-4" dense color="transparent">
        <img width="40px" src="@/assets/stocks/srcm.svg" alt="srcm" class="mr-2" />
        <p class="title font-weight-bold mb-0">Stock moniter</p>
        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <v-select
          @change="getContentlistdata('yes')"
          :readonly="issloading"
          style="max-width: 180px"
          v-model="screent0"
          hide-details
          append-icon="mdi-chevron-down"
          item-text="text"
          item-value="value"
          class="rounded-pill mr-3 d-none d-sm-flex"
          dense
          flat
          solo
          background-color="secbg"
          :items="screent0item"
          label="Condition"
        ></v-select>

        <v-select
          @change="getContentlistdata('yes')"
          :readonly="issloading"
          style="max-width: 140px"
          v-model="screent1"
          hide-details
          append-icon="mdi-chevron-down"
          item-text="text"
          item-value="value"
          class="rounded-pill d-none d-sm-flex"
          dense
          flat
          solo
          background-color="secbg"
          :items="screent1item"
          label="Condition"
        ></v-select>
      </v-toolbar>

       <v-toolbar class="elevation-0 my-4 d-sm-none" dense color="transparent">
        <v-select
          @change="getContentlistdata('yes')"
          :readonly="issloading"
          v-model="screent0"
          hide-details
          append-icon="mdi-chevron-down"
          item-text="text"
          item-value="value"
          class="rounded-pill mr-1"
          dense
          flat
          solo
          background-color="secbg"
          :items="screent0item"
          label="Condition"
        ></v-select>

        <v-select
          @change="getContentlistdata('yes')"
          :readonly="issloading"
          v-model="screent1"
          hide-details
          append-icon="mdi-chevron-down"
          item-text="text"
          item-value="value"
          class="rounded-pill ml-1"
          dense
          flat
          solo
          background-color="secbg"
          :items="screent1item"
          label="Condition"
        ></v-select>
      </v-toolbar>
      <v-divider></v-divider>
      <v-data-table must-sort :sort-by="['chp']" :sort-desc="[true]" mobile-breakpoint fixed-header :loading="issloading" class="rounded-lg overflow-y-auto" :headers="screenheader" :search="opensearch" :items="screentitems" :items-per-page="10">
        <template v-slot:[`item.tsym`]="{item}">
          <span @click="setSinglestock(item.tsym.split('-')[0], item)" class="font-weight-medium text-capitalize txt-dec-cust ws-p">{{ item.tsym }}</span>
        </template>

        <template v-slot:[`item.ltp`]="{item}">
          <p class="mb-0 lh-18">
            <span class="d-none" v-if="!uid">{{ setScrpitCH("", item, "SCR") }}</span>

            <span class="font-weight-medium maintext--text">₹<span :id="`ssdsc${item.token}ltp`" v-text="item.lp ? Number(item.lp).toFixed(2) : '0.00'"></span></span> <br />
            <span class="font-weight-medium fs-12 ws-p" :id="`ssdsc${item.token}chpclr`" :class="item.ch > 0 ? 'maingreen--text' : item.ch < 0 ? 'mainred--text' : 'subtext--text'">
              <span :id="`ssdsc${item.token}ch`" v-text="item.ch ? item.ch : '0.00'"> </span>
              <span :id="`ssdsc${item.token}chp`" v-text="` (${item.chp ? item.chp : '0.00'}%)`"></span
            ></span>
          </p>
        </template>

        <template v-slot:[`item.vol`]="{item}">
          <span class="font-weight-medium maintext--text">{{ item.v ? item.v : "0.00" }}</span>
        </template>
        <template v-slot:[`item.op`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`ssdsc${item.token}op`">{{ item.ap ? item.ap : "0.00" }}</span>
        </template>
        <template v-slot:[`item.cp`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`ssdsc${item.token}cp`">{{ item.c ? item.c : "0.00" }}</span>
        </template>
        <template v-slot:[`item.high`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`ssdsc${item.token}high`">{{ item.h ? item.h : "0.00" }}</span>
        </template>
        <template v-slot:[`item.low`]="{item}">
          <span class="font-weight-medium maintext--text" :id="`ssdsc${item.token}low`">{{ item.l ? item.l : "0.00" }}</span>
        </template>

        <template v-slot:no-data>
          <v-col cols="12" class="text-center pa-16">
            <div class="mx-auto">
              <img class="align-self-stretch mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" alt="no data" />
              <h5 class="txt-999 font-weight-regular">There is no data here yet!</h5>
            </div>
          </v-col>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {getConTentList, getLtpdata} from "@/components/mixins/getAPIdata";
import eventBus from "../../../eventBus.js";

export default {
  /* eslint-disable */
  data: () => ({
    bcitems: [
      {
        text: "Stocks",
        disabled: false,
        href: "/stocks",
      },
      {
        text: "Screener",
        disabled: true,
        href: "/stocks/screener",
      },
      {
        text: "this",
        disabled: true,
      },
    ],
    uid: null,
    mtoken: null,
    stoken: null,

    screent0item: [
      {text: "Volume & Price Up", value: "VolUpPriceUp"},
      {text: "Volume & Price Down", value: "VolUpPriceDown"},
      {text: "Weekly Volume Perc", value: "WeeklyVolPerc"},
      {text: "Weekly Volume Perc Potential", value: "WeeklyVolPercPotential"},
      {text: "2Day Price Perc Up", value: "2DayPricePercUp"},
      {text: "2Day Price Perc Down", value: "2DayPricePercDown"},
      {text: "Upper Circuit", value: "UpperCircuit"},
      {text: "Lower Circuit", value: "LowerCircuit"},
      {text: "Open High", value: "OpenHigh"},
      {text: "Open Low", value: "OpenLow"},
      {text: "High Break", value: "HighBreak"},
      {text: "Low Break", value: "LowBreak"},
      {text: "Weekly High Break", value: "W1HighBreak"},
      {text: "Weekly Low Break", value: "W1LowBreak"},
      {text: "2 Weeks High Break", value: "W2HighBreak"},
      {text: "2 Weeks Low Break", value: "W2LowBreak"},
      {text: "Monthly High Break", value: "M1HighBreak"},
      {text: "Monthly Low Break", value: "M1LowBreak"},
      {text: "3 Months High Break", value: "M3HighBreak"},
      {text: "3 Months Low Break", value: "M3LowBreak"},
      {text: "52 Weeks High Break", value: "W52HighBreak"},
      {text: "52 Weeks Low Break", value: "W52LowBreak"},

      {text: "R3 Break", value: "R3Break"},
      {text: "R2 Break", value: "R2Break"},
      {text: "R1 Break", value: "R1Break"},
      {text: "Pivot Break", value: "PivotBreak"},
      {text: "S1 Break", value: "S1Break"},
      {text: "S2 Break", value: "S2Break"},
      {text: "S3 Break", value: "S3Break"},
    ],
    screent0: "VolUpPriceUp",
    screent1item: [
      {text: "All", value: "A"},
      {text: "Nifty 50", value: "NIFTY50"},
      {text: "Nifty 500", value: "NIFTY500"},
      {text: "Nifty MIDCAP 50", value: "NIFTYMCAP50"},
      {text: "Nifty SMLCAP 50", value: "NIFTYSMCAP50"},
    ],
    screent1: "A",
    screentitems: [],

    issloading: true,
    opensearch: null,
  }),
  computed: {
    screenheader() {
      return [
        {text: "Symbol", value: "tsym", sortable: false, class: "ws-p"},
        {text: "Price", value: "ltp", sortable: false, align: "right", class: "ws-p"},
        {text: "Open", value: "op", align: "right", class: "ws-p"},
        {text: "High", value: "high", align: "right", class: "ws-p"},
        {text: "Low", value: "low", align: "right", class: "ws-p"},
        {text: "Close", value: "cp", align: "right", class: "ws-p"},
        {text: "Volume", value: "vol", align: "right", class: "ws-p"},
      ];
    },
  },
  mounted() {
    eventBus.$emit("tabBar-load");
    eventBus.$emit("login-event");
    eventBus.$on("user-event", () => {
      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        if (!this.uid && !this.stoken) {
          this.mtoken = sessionStorage.getItem("msession");
          this.stoken = sessionStorage.getItem("usession");
          this.uid = sessionStorage.getItem("userid");
        }
      }
      this.getContentlistdata();
    });

    eventBus.$on("web-scoketConn", (data, page) => {
      if (page == "stockSCR" && this.screentitems && typeof this.screentitems == "object") {
        this.optionChainDataParse(data);
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("user-event");
    // eventBus.$off('web-scoketConn');
  },
  methods: {
    setSinglestock(tsym, item) {
      if (this.uid) {
        let path = [0, item.token, item.exch, item.tsym];
        this.$router.push({name: "stocks details", params: {val: path}});
      } else {
        this.$router.push(`/stocks/${tsym.toLowerCase()}`);
      }
    },
    setScrpitCH(x, i, a) {
      let f = this.screentitems.findIndex((o) => o.token == i.token);
      this.screentitems[f]["ch"] = Number(i.lp) && Number(i.c) ? (Number(i.lp) - Number(i.c)).toFixed(2) : (0).toFixed(2);
      this.screentitems[f]["chp"] = Number(i.ch) && Number(i.lp) ? ((Number(i.ch) / Number(i.lp)) * 100).toFixed(2) : (0).toFixed(2);
    },
    async getContentlistdata(change) {
      if (change == "yes") {
        this.setWebsocket("unsub-D", this.screentitems, "sc");
      }
      this.issloading = true;
      let data = await getConTentList(["NSE", this.screent1, this.screent0]);
      if (data && data.length > 0) {
        this.screentitems = data;
        this.setWebsocket("sub", data, "sc");
      } else {
        this.screentitems = [];
      }
      this.issloading = false;
    },
    async setWebsocket(flow, data, is) {
      if (this.uid) {
        eventBus.$emit("web-scoketOn", flow, data, is, "stockSCR");
      } else {
        let raw = await getLtpdata(data);
        raw = raw.data;
      }
    },
    optionChainDataParse(data) {
      let s = this.screentitems.findIndex((o) => o.token == data.token);
      if (s >= 0 && this.screentitems[s].token == data.token) {
        this.screentitems[s].ltp = Number(data.lp).toFixed(2);
        this.screentitems[s]["ch"] = Number(data.ch) > 0 || Number(data.ch) < 0 ? Number(data.ch).toFixed(2) : (0).toFixed(2);
        this.screentitems[s]["chp"] = Number(data.chp).toFixed(2);
        this.screentitems[s]["vol"] = Number(data.volume);
        this.screentitems[s]["op"] = Number(data.open_price) ? Number(data.open_price).toFixed(2) : (0).toFixed(2);
        this.screentitems[s]["cp"] = Number(data.prev_close_price) ? Number(data.prev_close_price).toFixed(2) : (0).toFixed(2);
        this.screentitems[s]["high"] = Number(data.high_price) ? Number(data.high_price).toFixed(2) : (0).toFixed(2);
        this.screentitems[s]["low"] = Number(data.low_price) ? Number(data.low_price).toFixed(2) : (0).toFixed(2);

        let tag = document.getElementById(`ssdsc${data.token}ltp`);
        if (tag) {
          document.getElementById(`ssdsc${data.token}ltp`).innerHTML = this.screentitems[s].ltp;
          document.getElementById(`ssdsc${data.token}ch`).innerHTML = this.screentitems[s].ch;
          document.getElementById(`ssdsc${data.token}chp`).innerHTML = ` (${this.screentitems[s].chp}%)`;
          eventBus.$emit("color-event", `ssdsc${data.token}chpclr`, this.screentitems[s].ch > 0 ? "maingreen--text" : this.screentitems[s].ch < 0 ? "mainred--text" : "subtext--text");
          document.getElementById(`ssdsc${data.token}op`).innerHTML = this.screentitems[s].op;
          document.getElementById(`ssdsc${data.token}cp`).innerHTML = this.screentitems[s].cp;
          document.getElementById(`ssdsc${data.token}high`).innerHTML = this.screentitems[s].high;
          document.getElementById(`ssdsc${data.token}low`).innerHTML = this.screentitems[s].low;
        }
        // this.$set(this.pdmwdata, p, this.pdmwdata[p]);
      }
    },
  },
};
</script>
