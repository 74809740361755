<template>
  <div>
    <div>
      <router-view @snack="snackAlert" @menudialog="setMenudialog" @loader="setLoaderstatus" @gologin="goSso" @receive="setReceives" />
    </div>
  </div>
</template>

<script>
// import apiurl from '../../apiurl.js'
import eventBus from "../../eventBus.js";
import {getMFalldata, getBestMF, getIposIpo, getIposSme, getIposPerform, getBondGsec, getBondTbill, getBondSdl, getBondSgb, getCollections} from "@/components/mixins/getAPIdata";
// getInvHoldings, getLtpdata
export default {
  data: () => ({
    uid: null,
    token: null,

    mfdatas: [],
    iposdatas: {},
    bondsdatas: {},
    collsdatas: [],
    holdingdata: [],

    datastat: [],
  }),
  created() {
    this.$emit("loader", 1);
    this.getMfApis();
    this.getIposApis();
    this.getBondsApis();
    this.getCollsApis();

    var self = this;
    document.onkeypress = function (e) {
      e = e || window.event;
      if (e.keyCode >= 97 && e.keyCode <= 122) {
        self.setSsdtab();
      }
    };
  },
  mounted() {
    this.uid = sessionStorage.getItem("userid");
    this.token = sessionStorage.getItem("usession");

    // eventBus.$on("tabBar-load", (page) => {
    //   // this.sendPagedata(page);
    //   // this.setTabway();
    // });

    eventBus.$on("color-event", (id, value) => {
      this.setColorstyle(id, value);
    });

    // let stat = sessionStorage.getItem("c3RhdHVz");
    // if (stat == "dmFsaWR1c2Vy" && this.holdingdata.length == 0) {
    //   this.getHoldingbook();
    // }
  },
  beforeDestroy() {
    eventBus.$off("tabBar-load");
  },
  methods: {
    setSsdtab() {
      eventBus.$emit("addscript-wl", "", "searchss");
    },
    setColorstyle(id, value) {
      var idx = document.getElementById(id).classList;
      if (!idx.value.includes("maingreen--text") && value == "maingreen--text") {
        idx.add("maingreen--text");
        idx.remove("mainred--text");
        idx.remove("subtext--text");
      }
      if (!idx.value.includes("mainred--text") && value == "mainred--text") {
        idx.add("mainred--text");
        idx.remove("maingreen--text");
        idx.remove("subtext--text");
      }
      if (!idx.value.includes("subtext--text") && value == "subtext--text") {
        idx.add("subtext--text");
        idx.remove("maingreen--text");
        idx.remove("mainred--text");
      }

      // console.log(idx.value, value, idx.value.includes("maingreen--text"), idx.value.includes("mainred--text"), idx.value.includes("subtext--text"));
    },
    setReceives(page) {
      // this.setTabway(bread);
      this.sendPagedata(page);
    },
    sendPagedata(page) {
      let res;
      if (page == "stk") {
        setTimeout(() => {
          eventBus.$emit("setappbar-event", res, this.apptab);
        }, 10);
      } else if (page == "mf") {
        if (this.mfdatas && this.mfdatas.stat == "Ok") {
          res = this.mfdatas;
        } else {
          this.setCheckdatastat(0);
        }
      } else if (page == "ipos") {
        if (this.iposdatas && Object.keys(this.iposdatas).length == 3) {
          res = this.iposdatas;
        } else {
          this.setCheckdatastat(1);
        }
      } else if (page == "bonds") {
        if (this.bondsdatas && Object.keys(this.bondsdatas).length == 4) {
          res = this.bondsdatas;
        } else {
          this.setCheckdatastat(2);
        }
      } else if (page == "collection") {
        if (this.collsdatas && this.collsdatas.stat == "ok") {
          res = this.collsdatas;
        } else {
          this.setCheckdatastat(3);
        }
      }
      // else if (page == "holding") {
      //   if (this.holdingdata.Bonds || this.holdingdata.Equity) {
      //     res = this.holdingdata;
      //   } else {
      //     this.setCheckdatastat(3);
      //   }
      // }
      // page == "holding" ? stat == "dmFsaWR1c2Vy" && this.holdingdata && (this.holdingdata.Bonds || this.holdingdata.Equity) :
      // let stat = sessionStorage.getItem("c3RhdHVz");
      // && this.collsdatas.stat == "ok"
      if (this.mfdatas.stat == "Ok" && Object.keys(this.iposdatas).length == 3 && Object.keys(this.bondsdatas).length == 4 && this.collsdatas.stat == "ok") {
        // res = JSON.parse(JSON.stringify(res))
        setTimeout(() => {
          eventBus.$emit("setRec-event", res);
          eventBus.$emit("setappbar-event", res, this.apptab);
        }, 10);
      }
    },
    // setTabway() {
    //   let path = window.location;
    //   if (path.pathname.includes("stocks")) {
    //     this.apptab = 0;
    //   } else if (path.pathname.includes("mutualfund")) {
    //     this.apptab = 1;
    //   } else if (path.pathname.includes("ipo")) {
    //     this.apptab = 2;
    //   } else if (path.pathname.includes("bonds")) {
    //     this.apptab = 3;
    //   } else if (path.pathname.includes("collection")) {
    //     this.apptab = 4;
    //   }

    //   if (path.pathname.includes("orders")) {
    //     this.sidetab = 1;
    //   } else {
    //     this.sidetab = 0;
    //   }
    // },
    setMenudialog(type, item, value) {
      if (type == "mforder" || type == "bondorder" || type == "iposorder") {
        // this.$emit('menudialog', 'iposorder', [item], type)
        this.$emit("menudialog", type, item, value);
      }
    },
    snackAlert(color, msg) {
      eventBus.$emit("snack-event", color, msg);
    },
    setLoaderstatus(value) {
      this.$emit("loader", value);
    },
    goSso() {
      this.$emit("gologin");
    },

    async getMfApis(page) {
      let res = await getMFalldata(["normal", "Y", "Y"]);
      if (res != 500) {
        res["bestmfdata"] = await getBestMF();
        for (let w = 0; w < res.data.length; w++) {
          let rep = res.data[w].SCHEME_CATEGORY.replace("Scheme", "").replace(" ", "");
          res.data[w].SCHEME_CATEGORY = rep == "Other " || rep == "" ? "others" : rep.trim();
          res.data[w].SCHEME_SUB_CATEGORY = res.data[w].SCHEME_SUB_CATEGORY == "" ? "Other" : res.data[w].SCHEME_SUB_CATEGORY.trim();

          let arr = res.data[w].Scheme_Name.split("-").slice(0);
          for (let d = 0; d < arr.length; d++) {
            res.data[w]["splito"] = arr[d].includes("GROWTH") ? "GROWTH" : arr[d].includes("IDCW PAYOUT") ? "IDCW PAYOUT" : arr[d].includes("IDCW REINVESTMENT") ? "IDCW REINVESTMENT" : arr[d].includes("IDCW") ? "IDCW" : "NORMAL";
          }
          res.data[w]["splitt"] = res.data[w].SCHEME_CATEGORY.toUpperCase();
          res.data[w]["splitr"] = res.data[w].SCHEME_SUB_CATEGORY.replace(/Fund|Hybrid|_/gi, "").toUpperCase();
        }
        res["cat"] = res.data.reduce((acc, curr) => {
          const {SCHEME_CATEGORY} = curr;
          if (acc[SCHEME_CATEGORY]) ++acc[SCHEME_CATEGORY];
          else acc[SCHEME_CATEGORY] = 1;
          return acc;
        }, {});
        res["sub"] = res.data.reduce((acc, curr) => {
          const {SCHEME_SUB_CATEGORY} = curr;
          if (acc[SCHEME_SUB_CATEGORY]) false;
          else acc[SCHEME_SUB_CATEGORY] = false;
          return acc;
        }, {});
        res["amc"] = res.data.reduce((acc, curr) => {
          const {AMC_Code} = curr;
          if (acc[AMC_Code]) false;
          else acc[AMC_Code] = false;
          return acc;
        }, {});

        // delete res['cat']['others'];
        res["cat"]["Other"] = res["cat"]["others"];
        delete res["cat"]["others"];
        res.sub = Object.keys(res.sub)
          .sort()
          .reduce((accumulator, key) => {
            accumulator[key] = res.sub[key];
            return accumulator;
          }, {});
        res.amc = Object.keys(res.amc)
          .sort()
          .reduce((accumulator, key) => {
            accumulator[key] = res.amc[key];
            return accumulator;
          }, {});

        let topdata = res.data;
        topdata.sort((a, b) => Number(b.AUM) - Number(a.AUM));
        res["topdata"] = topdata.slice(0, 100);
        this.mfdatas = res;
      } else {
        res["bestmfdata"] = [];
        eventBus.$emit("snack-event", 0, "AxiosError: Network Error");
      }
      this.setCheckdatastat(0);
      page == "mf" ? (eventBus.$emit("setRec-event", this.mfdatas), eventBus.$emit("setappbar-event", res, this.apptab)) : "";
    },
    async getIposApis(page) {
      this.iposdatas["ipo"] = await getIposIpo();
      this.iposdatas["sme"] = await getIposSme();
      this.iposdatas["perf"] = await getIposPerform(new Date().getFullYear());
      this.setCheckdatastat(1);
      page == "ipos" ? eventBus.$emit("setRec-event", this.iposdatas) : "";
    },
    async getBondsApis(page) {
      this.bondsdatas["gsec"] = await getBondGsec();
      this.bondsdatas["tbill"] = await getBondTbill();
      this.bondsdatas["sdl"] = await getBondSdl();
      this.bondsdatas["sgb"] = await getBondSgb();
      this.setCheckdatastat(2);
      page == "bonds" ? eventBus.$emit("setRec-event", this.bondsdatas) : "";
    },
    async getCollsApis(page) {
      this.collsdatas = await getCollections();
      this.setCheckdatastat(3);
      page == "collection" ? eventBus.$emit("setRec-event", this.collsdatas) : "";
    },

    // async getHoldingbook(page) {
    //   let res = await getInvHoldings([this.uid, this.token]);
    //   this.holdingdata["Equity"] = [];
    //   this.holdingdata["Bonds"] = [];
    //   if (res && res.Holdings && res != 500) {
    //     let ltpraw = [];
    //     res.Holdings.forEach((r) => {
    //       ltpraw.push({token: r.Token, exch: r.Exchange});
    //     });
    //     let raw = await getLtpdata(ltpraw);

    //     for (let q = 0; q < res.Holdings.length; q++) {
    //       const ele = res.Holdings[q];
    //       ele["ltp"] = raw.data[ele.Token].lp;
    //       if (ele.seg_type == "Bonds") {
    //         ele["series"] = ele.SCRIP_SYMBOL.split("-")[1] == "TB" ? "T-BILL" : ele.SCRIP_SYMBOL.split("-")[1] == "GS" ? "G-SEC" : ele.SCRIP_SYMBOL.split("-")[1] == "GB" ? "SGB" : ele.SCRIP_SYMBOL.split("-")[1];
    //         this.holdingdata.Bonds.push(ele);
    //       } else if (ele.seg_type == "Equity") {
    //         this.holdingdata.Equity.push(ele);
    //       }
    //     }
    //   }
    //   this.setCheckdatastat(2);
    //   page == "holding" ? eventBus.$emit("setRec-event", this.holdingdata) : "";
    // },

    setCheckdatastat() {
      //  if(this.mfdatas && this.iposdatas && this.bondsdatas && this.collsdatas) {
      //     this.$emit("loader", 0);
      //     eventBus.$emit("setRec-event", "stat_ok");

      //  }
      // stack
      // let stat = sessionStorage.getItem("c3RhdHVz");
      // stack == 3 ? stat == "dmFsaWR1c2Vy" && this.holdingdata && (this.holdingdata.Bonds || this.holdingdata.Equity) :
      //  && this.collsdatas.stat == "ok"
      if (this.apptab == 0) {
        this.$emit("loader", 0);
      } else if (this.mfdatas.stat == "Ok" && Object.keys(this.iposdatas).length == 3 && Object.keys(this.bondsdatas).length == 4 && this.collsdatas.stat == "ok") {
        this.$emit("loader", 0);
        eventBus.$emit("setRec-event", "stat_ok");
      } else {
        this.$emit("loader", 1);
      }
      // this.$emit("loader", 0);
    },
  },
};
</script>

<style>
.pos-rlt {
  position: relative !important;
}

.pos-abs {
  position: absolute !important;
}
</style>
