<template>
  <div>
    <div class="mb-6">
      <v-toolbar flat dense class="tool-sty crd-trn">
        <p class="title font-weight-bold mb-0">Tradebook ({{ orderbookdata ? orderbookdata.length : 0 }})</p>

        <v-spacer></v-spacer>
        <v-text-field style="max-width:220px;" v-model="opensearch" hide-details prepend-inner-icon="mdi-magnify"
          label="Search for Stocks" class="rounded-pill mr-4" dense flat solo background-color="secbg"></v-text-field>

        <v-select style="max-width:180px;" v-model="filter" hide-details append-icon="mdi-chevron-down"
          prepend-inner-icon="mdi-playlist-check" class="rounded-pill" dense flat solo background-color="secbg"
          :items="filters" label="Filter"></v-select>
          <v-icon class="ml-3 cursor-p" @click="getOrderbook()" color="maintext" size="24">mdi-reload</v-icon>
      </v-toolbar>

      <v-data-table must-sort :sort-by="['norentm']" :sort-desc="[true]" hide-default-footer fixed-header
        :loading="loading" class="mt-3 rounded-lg overflow-y-auto" style="border-radius: 4px; border: 1px solid var(--outline);"
        height="480px" :headers="orderheader" :search="opensearch" :items="orderbookdata" :items-per-page="-1">
        <template v-slot:body="{ items, headers }">
          <tbody v-if="orderbookdata && orderbookdata.length > 0">
            <tr class="table-row" v-for="(item, o) in items" :key="o">
              <td><span class="font-weight-medium maintext--text">{{ item.norentm ? item.norentm.slice(0, 8) : '-'
                  }}</span>
              </td>
              <td> <v-chip small :color="item.trantype == 'B' ? '#ECF8F1' : '#FCF3F3'"
                  :text-color="item.trantype == 'B' ? 'maingreen' : 'mainred'"
                  :style="`border: 1px solid ${item.trantype == 'B' ? '#C1E7BA' : '#FFCDCD'}; border-radius: 5px; padding: 10px 8px !important;`">
                  <span class="font-weight-medium fs-12">{{ item.trantype == 'B' ? 'BUY' : 'SELL' }}</span>
                </v-chip></td>
              <td class="pos-rlt">
                <p class="font-weight-medium maintext--text mb-0 table-hov-text ws-p mr-4">{{ item.tsym ? item.tsym : '' }}
                  <span class="ml-1 subtext--text fs-10">{{ item.exchs ? item.exchs : item.exch ? item.exch : '' }}</span>
                </p>
                <div @click.stop class="pos-abs table-hov" style="top:15px; right:0;">
                  <v-btn @click="switchbus.$emit('menudialog', 'order', item.token, item.exch, item.tsym, 'b')"
                    min-width="20px" color="maingreen" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                    B
                  </v-btn>
                  <v-btn @click="switchbus.$emit('menudialog', 'order', item.token, item.exch, item.tsym, 's')"
                    min-width="20px" color="mainred" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                    S
                  </v-btn>
                  <v-btn @click="setSSDtab('chart', item.token, item.exch, item.tsym)" style="border: 1px solid var(--outline);"
                    min-width="20px" color="mainbg" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                    <v-icon size="18" color="maintext">mdi-chart-line-variant</v-icon>
                  </v-btn>
                  <v-tooltip top color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-btn @click="switchbus.$emit('menudialog', 're-order', item.token, item.exch, item.tsym, item.trantype.toLowerCase(), item)" style="border: 1px solid var(--outline);"
                          min-width="20px" color="mainbg" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                          <v-icon size="18" color="maintext">mdi-autorenew</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>Repeat order</span>
                  </v-tooltip>
                  <v-menu close-on-click absolute offset-y class="table-menu">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" style="border: 1px solid var(--outline);" min-width="20px" color="mainbg"
                        class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                        <v-icon size="20" color="maintext">mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-card class="table-menu-list">
                      <v-list dense>
                        <div v-for="(m, k) in menulist" :key="k">
                          <v-list-item
                            @click="m.type != '' ? setSSDtab(m.type, item.token, item.exch, item.tsym) : setHoldingrowdata(item)"
                            class="pl-3 pr-6">
                            <v-list-item-icon class="mr-3 text-center">
                              <img v-if="m.icon > 2" width="20px" class="pl-1"
                                :src="require(`@/assets/orderbook/${m.icon}.svg`)" />
                              <v-icon v-else color="#506D84">{{ m.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title class="subline--text font-weight-medium fs-14">{{ m.name
                                }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider v-if="m.hr" class="mx-3"></v-divider>
                        </div>
                      </v-list>
                    </v-card>
                  </v-menu>
                </div>
              </td>
              <td> <v-chip v-if="item.s_prdt_ali" small class="table-hov-prd" text-color="subtext"
                  style="border-radius: 5px; padding: 10px 8px !important;">
                  <span class="font-weight-medium fs-12">{{ item.s_prdt_ali }}</span>
                </v-chip></td>
              <td>
                <p class="font-weight-medium maintext--text mb-0">{{ item.qty ? item.qty : '' }}</p>
              </td>
              <!-- <td class="text-right font-weight-medium">
                <p class="font-weight-medium black--text mb-0">{{ item.ltp ? `₹${item.ltp}` : '0.00' }}</p>
              </td> -->
              <td class="text-right font-weight-medium">
                <p class="font-weight-medium maintext--text mb-0">{{ item.prc ? Number(item.prc).toFixed(2) : '' }}</p>
              </td>
              <td class="text-right font-weight-medium">
                <p class="font-weight-medium maintext--text mb-0">{{ item.avgprc ? Number(item.qty * item.prc).toFixed(2) :
                  '' }}</p>
              </td>
              <td class="text-right font-weight-medium">
                <p class="font-weight-medium maintext--text mb-0">{{ item.norenordno ? item.norenordno : '' }}</p>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <td :colspan="headers.length" class="text-center">
              <div class="mx-auto py-16 mt-16">
                <img class="mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" />
                <h4 class="subtext--text font-weight-regular caption">There is no
                  order <br>
                  data here
                  yet!</h4>
              </div>
            </td>
          </tbody>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
// import apiurl from '../../apiurl.js'
import eventBus from '../../eventBus.js';

import { getMTradebook } from '../../components/mixins/getAPIdata.js'
// import dataT from '../../dataT'

export default {
  data: () => ({
    loading: false,

    opensearch: null,
    filter: null,
    filters: [],

    menulist: [
      { name: 'Create GTT / GTC', icon: 4, type: 'cGTT' },
      { name: 'Create Alert', icon: 5, type: 'alert' },
      { name: 'Market Depth', icon: 6, type: 'depth' },
      { name: 'Chart', icon: 7, type: 'chart', hr: true },
      { name: 'Fundamentals', icon: 9, type: 'Funda' },
      { name: 'Details', icon: 10, type: '' }
    ],

    switchbus: null,
    orderbookdata: []
  }),
  computed: {
    orderheader() {
      return [
        { text: 'Time', value: 'norentm', },
        { text: 'Type', value: 'trantype', sortable: false },
        { text: 'Instrument', value: 'tsym', sortable: false, width: '32%' },
        { text: 'Product', value: 's_prdt_ali', sortable: false },
        { text: 'Qty', value: 'qty', sortable: false, width: '6%' },
        // { text: 'LTP', value: 'ltp', sortable: false, width: '8%', align:'right' },
        { text: 'Price', value: 'prc', sortable: false, width: '8%', align: 'right' },
        { text: 'Trade price', value: 'avgprc', sortable: false, width: '8%', align: 'right', class: 'ws-p' },
        { text: 'Order no', value: 'norenordno', sortable: false, width: '8%', align: 'right', class: 'ws-p' },
      ]
    }
  },
  created() {
    this.switchbus = eventBus;
  },
  mounted() {
    let res = sessionStorage.getItem("c3RhdHVz");
    if (res == "dmFsaWR1c2Vy") {
      this.getOrderbook();
    } else {
      this.$router.push('/')
    }

    eventBus.$on('orderbook-update', (book) => {
      if (book == 'orders') {
        this.getOrderbook();
      }
    })
  },
  beforeDestroy() {
    eventBus.$off('orderbook-update');
  },
  methods: {
    async getOrderbook() {
      this.orderbookdata = []
      let uid = sessionStorage.getItem('userid');
      let tok = sessionStorage.getItem('msession');
      this.loading = true;
      let data = `jData={"uid":"${uid}","actid":"${uid}"}&jKey=${tok}`;

      let config = await getMTradebook(data)
      if (config && config.length > 0) {
        this.orderbookdata = config;
      } else {
        this.orderbookdata = []
        eventBus.$emit('snack-event', 2, config.emsg ? config.emsg : config)
      }
      // this.orderbookdata = dataT;
      this.loading = false;
    },
    setSSDtab(type, token, exch, tsym) {
      if (type == 'alert') {
        eventBus.$emit('menudialog', 'alert', token, exch, tsym)
      } else if (type == 'cGTT') {
        this.holdingdrawer = false;
        eventBus.$emit('menudialog', 'order-GTT', token, exch, tsym, 'b');
      } else {
        let path = [type, token, exch, tsym]
        this.$router.push({ name: 'stocks details', params: { val: path } })
      }
    },
  }
}
</script>