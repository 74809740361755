<template>
    <div>
        <v-row no-glutters>
            <v-col cols="12">
                <v-card style="border: 1px solid var(--outline);" class="crd-trn elevation-0 rounded-lg mb-8 mb-md-0">
                    <v-toolbar class="tool-sty elevation-0 py-4 mb-7 px-1 crd-trn" dense>
                        <v-list-item class="pl-0">
                            <v-list-item-icon class="mr-4">
                                <img width="80%" :src="require(`@/assets/ipos/main-card-4${$vuetify.theme.dark ? 'd' : ''}.svg`)" />
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold title">IPO Performance {{ year
                                    }}</v-list-item-title>
                                <v-list-item-subtitle class="fs-12 subtext--text">Monitor the IPO Performance of Mainline <br
                                        class="d-md-none"> IPO
                                    Stocks listed at BSE and NSE</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-spacer></v-spacer>
                        <v-text-field :disabled="loading" style="width:160px;" v-model="year" hide-details
                            prepend-inner-icon="" label="Year" class="rounded-pill mr-4 d-none d-md-flex" dense flat
                            solo background-color="secbg">
                            <template #append>
                                <div>
                                    <v-icon size="20" @click="setYearupdate('+')"
                                        class="mt-auto">mdi-chevron-up</v-icon>
                                    <v-icon size="20" @click="setYearupdate('-')"
                                        class="mb-auto">mdi-chevron-down</v-icon>
                                </div>
                            </template>
                        </v-text-field>

                        <v-text-field style="max-width:220px;" v-model="opensearch" hide-details
                            prepend-inner-icon="mdi-magnify" label="Search bonds"
                            class="rounded-pill mr-4  d-none d-md-flex" dense flat solo
                            background-color="secbg"></v-text-field>
                    </v-toolbar>
                    <v-toolbar class="tool-sty elevation-0 py-0 mb-2 px-0 d-md-none" color="#fff" dense>
                        <v-text-field style="max-width:100px;" :disabled="loading" v-model="year" hide-details
                            prepend-inner-icon="" label="Year" class="rounded-pill mr-4" dense flat solo
                            background-color="secbg">
                            <template #append>
                                <div>
                                    <v-icon size="20" @click="setYearupdate('+')"
                                        class="mt-auto">mdi-chevron-up</v-icon>
                                    <v-icon size="20" @click="setYearupdate('-')"
                                        class="mb-auto">mdi-chevron-down</v-icon>
                                </div>
                            </template>
                        </v-text-field>

                        <v-text-field v-model="opensearch" hide-details prepend-inner-icon="mdi-magnify"
                            label="Search bonds" class="rounded-pill" dense flat solo
                            background-color="secbg"></v-text-field>
                    </v-toolbar>

                    <v-data-table :sort-by="['dateis']" :sort-desc="[true]" :search="opensearch" must-sort
                        hide-default-footer fixed-header :loading="loading"
                        class="rounded-0 overflow-y-auto d-none d-md-block" :headers="tradeheader"
                        height="calc(100vh - 180px)" :items="iposdatas"
                        :items-per-page="iposdatas && iposdatas.length > 0 ? showtable : 0" item-key="Scheme_Code">

                        <template v-slot:body="{ items, headers }">
                            <tbody v-if="iposdatas && iposdatas.length > 0">
                                <tr class="table-row" v-for="(item, o) in items" :key="o">
                                    <td class="pos-rlt" cols="1">
                                        <P class="fs-13 font-weight-medium mb-0 txt-000 table-hov-text mt-2">
                                            {{
                                    item['Company Name'] ?
                                        item['Company Name'] : '' }}
                                        </P>
                                        <!-- <v-chip-group column class="mb-1">
                                            <v-chip v-for="(q, e) in item['Stock Exchanges']" :key="e" color="secbg"
                                                x-small class="table-hov-prd" text-color="#666"
                                                style="border-radius: 5px; padding: 10px 8px !important;">
                                                <span class="font-weight-medium fs-10">
                                                    {{ q }}
                                                </span>
                                            </v-chip>
                                            <v-chip color="secbg" x-small class="table-hov-prd" text-color="#666"
                                                style="border-radius: 5px; padding: 10px 8px !important;">
                                                <span class="font-weight-medium fs-10">
                                                    {{ item.ISIN ? item.ISIN : '' }}
                                                </span>
                                            </v-chip>
                                        </v-chip-group> -->
                                    </td>
                                    <td class="font-weight-medium">{{ item.listed_date ? item.listed_date : '' }}</td>
                                    <td class="text-right font-weight-medium">₹{{ item.Price_Range ?
                                    item.Price_Range.toFixed(2) : '0.00'
                                        }}</td>
                                    <td class="text-right font-weight-medium">₹{{ item.ClsPric ? item.ClsPric.toFixed(2)
                                    : '0.00' }}</td>
                                    <td class="text-right font-weight-medium"
                                        :class="item.listing_gain > 0 ? 'txt-gre' : item.listing_gain < 0 ? 'txt-red' : 'black--text'">
                                        ₹{{ item.listing_gain ? item.listing_gain.toFixed(2) :
                                    '0.00' }}</td>
                                    <td class="text-right font-weight-medium"
                                        :class="item.listing_gain_per > 0 ? 'txt-gre' : item.listing_gain_per < 0 ? 'txt-red' : 'black--text'">
                                        {{ item.listing_gain_per ? item.listing_gain_per.toFixed(2) :
                                    '0.00' }}%</td>
                                </tr>
                                <tr v-if="showtable < iposdatas.length">
                                    <td :colspan="headers.length">
                                        <v-btn color="primary" plain class="elevation-0 text-none font-weight-bold py-4"
                                            @click="showtable += 24" block>show more</v-btn>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <td :colspan="headers.length" class="text-center">
                                    <div class="mx-auto py-16 mt-16">
                                        <img class="mx-auto" width="80px"
                                            :src="require('@/assets/no data folder.svg')" />
                                        <h4 class="txt-999 font-weight-regular caption">There is no IPOs Performanced on {{ year
                                            }}
                                            data here yet!</h4>
                                    </div>
                                </td>
                            </tbody>
                        </template>
                    </v-data-table>

                    <div class="d-md-none">
                        <div v-if="iposdatas.length > 0">
                            <v-card class="rounded-lg mb-3" outlined v-for="(p, t) in iposdatas.length < showtable ? iposdatas.slice(0, showtable) : iposdatas"
                                :key="t">
                                <div>
                                    <v-card color="#FAFBFF" class="elevation-0 rounded-b-0 rounded-t-lg">
                                        <v-list-item class="px-2">
                                            <v-list-item-content>
                                                <v-list-item-title class="mb-0 fs-15">{{ p['Company Name']
                                                    }}</v-list-item-title>
                                                <v-list-item-subtitle class="fs-12">{{ p.covertdate ?
                                    p.covertdate.slice(4, 16) :
                                    ''
                                                    }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-icon>
                                                <span class="fs-14"
                                                    :class="p.listing_gain_per > 0 ? 'txt-gre' : p.listing_gain_per < 0 ? 'txt-red' : 'black--text'">
                                                    {{ p.listing_gain_per ? p.listing_gain_per : '0.00' }}%
                                                </span>
                                            </v-list-item-icon>
                                        </v-list-item>
                                    </v-card>
                                    <v-card class="elevation-0 rounded-t-0 rounded-b-lg px-2 py-1">
                                        <v-row no-glutters>
                                            <v-col cols="6" class="subtext--text fs-13">Issue price<span
                                                    class="fs-14 black--text font-weight-medium">
                                                    ₹{{ p.Price_Range
                                                    }}</span></v-col>
                                            <v-col cols="6" class="subtext--text fs-13 text-right">Listing gain <span
                                                    class="fs-14 font-weight-medium"
                                                    :class="p.listing_gain > 0 ? 'txt-gre' : p.listing_gain < 0 ? 'txt-red' : 'black--text'">
                                                    ₹{{
                                    p.listing_gain ? p.listing_gain : '0.00' }}</span></v-col>
                                        </v-row>
                                    </v-card>
                                </div>
                            </v-card>
                            <v-btn v-if="showtable < iposdatas.length" color="primary" plain class="elevation-0 text-none font-weight-bold py-4"
                                @click="showtable += 24" block>show
                                more</v-btn>
                        </div>
                        <div v-else>
                            <v-skeleton-loader v-for="n in 4" :key="n" class="rounded-lg mb-3" width="100%" height="90"
                                type="card"></v-skeleton-loader>
                        </div>
                    </div>

                </v-card>
            </v-col>
            <v-col cols="3" class="d-none d-md-none"></v-col>
        </v-row>
    </div>
</template>

<script>
import eventBus from '../../../eventBus.js';
import { getIposPerform } from '@/components/mixins/getAPIdata';

export default {

    data: () => ({
        iposdatas: [],
        loading: true,
        opensearch: null,
        showtable: 24,
        uid: null,

        year: new Date().getFullYear()
    }),

    computed: {
        tradeheader() {
            return [
                { text: `${this.year} listings`, value: 'Company Name', sortable: false, width: '30%', },
                { text: 'Listed on', value: 'listed_date', sortable: false },
                { text: 'Issue price', value: 'Price_Range', align: 'right' },
                { text: 'Close price', value: 'ClsPric', align: 'right' },
                // { text: 'FV', value: 'faceValue', sortable: false, align: 'right' },
                { text: 'Gain/Loss', value: 'listing_gain', align: 'right' },
                { text: 'Gain/Loss (%)', value: 'listing_gain_per', align: 'right' },
                // { text: 'Frequency', value: 's_prdt_ali', sortable: false }
            ]
        }
    },
    created() {
        // 
    },

    async mounted() {
    eventBus.$emit('tabBar-load');

        // eventBus.$emit('login-event');
        this.$emit('receive', 'ipos', 'Performance');
        eventBus.$on('setRec-event', (res) => {
            if (res == 'stat_ok') {
                this.$emit('receive', 'ipos', 'Performance');
            } else {
                this.setSortdata(res.perf);
            }
        });
        // eventBus.$on('user-event', () => {
        let res = sessionStorage.getItem("c3RhdHVz");
        if (res == "dmFsaWR1c2Vy") {
            this.token = sessionStorage.getItem("usession");
            this.uid = sessionStorage.getItem("userid");
        } else {
            this.token = ''
            this.uid = ''
        }
        // })
    },
    beforeDestroy() {
        // eventBus.$off('user-event');
        eventBus.$off('setRec-event');
    },
    methods: {
        setSortdata(data) {
            if (data && data.data) {
                for (let s = 0; s < data.data.length; s++) {
                    const element = data.data[s];
                    element['dateis'] = new Date(element.listed_date).toISOString().replace('-', '/').split('T')[0].replace('-', '/');
                    element.listed_date = new Date(element.listed_date).toLocaleString('en-gb', { day: '2-digit', month: 'short', year: 'numeric' })
                }
                this.iposdatas = data.data;
            } else {
                this.iposdatas = [];
            }
            this.loading = false;
        },
        async getiposdata() {
            this.loading = true;
            let data = await getIposPerform(this.year);
            this.setSortdata(data);
        },
        IposOrder(item, type) {
            let res = sessionStorage.getItem("c3RhdHVz");
            if (res == "dmFsaWR1c2Vy") {
                this.$emit('menudialog', 'iposorder', item, type)
            } else {
                this.token = '';
                this.uid = '';
                this.$emit('gologin')
            }
        },
        setYearupdate(opt) {
            let year = new Date().getFullYear()
            if (opt == '+' && year != this.year) {
                this.year = this.year + 1;
                this.getiposdata();
            } else if (opt == '-') {
                this.year = this.year - 1;
                this.getiposdata();
            }
        },
    },
}
</script>

<style>
.stk-land-crds {
    background-color: linear-gradient(270deg, #FFF 77.04%, #F6F6F6 115%), #FFF !important;
    box-shadow: 0px 38.519px 25.482px 0px rgba(83, 30, 0, 0.04), 0px 20px 13px 0px rgba(83, 30, 0, 0.04), 0px 8.148px 6.519px 0px rgba(83, 30, 0, 0.03), 0px 1.852px 3.148px 0px rgba(83, 30, 0, 0.02) !important;
}
</style>